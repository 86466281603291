import { z } from "zod";
import { PhoneNumberUtil as PNU, PhoneNumberType } from "google-libphonenumber";

export const CODE_LENGTH = 4;

/** Shared */

const password = z
  .string()
  .min(8)
  .max(32)
  .regex(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{0,}$/,
    "Must contain one uppercase, one lowercase and one number."
  );

const resetToken = z
  .string()
  .regex(/^[a-f\d]{24}$/i, "Invalid or expired token (reset password links expire after 24hrs)");

/** Schemas */

export const LoginSignUpSchema = z.object({
  email: z.string().email(),
});

export const LoginSchema = z.object({
  password,
  staySignedIn: z.boolean(),
});

export const SignUpSchema = z.object({
  firstName: z.string().min(1, "Must not be empty.").max(30),
  lastName: z.string().min(1, "Must not be empty.").max(30),
  countryCode: z.number(),
  mobileNumber: z.string().refine((value) => {
    if (value.length < 2) return false;

    const phoneUtil = PNU.getInstance();

    const number = phoneUtil.parseAndKeepRawInput(value, "AU");

    const isValid = phoneUtil.isValidNumberForRegion(number, "AU");
    const isMobile = phoneUtil.getNumberType(number) === PhoneNumberType.MOBILE;

    return isValid && isMobile;
  }, "Must be a valid mobile number."),
  password,
  marketingComms: z.boolean(),
});

export const VerifyMobileSchema = z.object({
  code: z
    .string()
    .regex(/[0-9]+$/)
    .length(CODE_LENGTH),
});

export const ResetPasswordSchema = z.object({
  password,
  resetToken,
});

export const ResetPasswordRequestSchema = z.object({
  email: z.string().email(),
});

export const ContactSchema = z.object({
  email: z.string().email(),
  subject: z.string().min(1, "Must not be empty"),
  body: z.string().min(1, "Must not be empty"),
});

export const CompetitionSchema = z.object({
  competitionAnswer: z
    .string()
    .min(1, "Must not be empty.")
    .regex(/^(?:\s*\S+(?:\s+\S+){0,24})?\s*$/, "Maximum 25 words only."),
  // .regex(/^\w+(?:\s+\w+){0,25}$/, "Maximum 25 words only."),
  fullName: z.string().min(1, "Must not be empty.").max(30),
  email: z.string().email(),
  postcode: z
    .string()
    .regex(/[0-9]+$/, "Must be a number.")
    .length(4),
});

/** Types */

export type LoginSignUpType = z.infer<typeof LoginSignUpSchema>;
export type LoginType = z.infer<typeof LoginSchema>;
export type SignUpType = z.infer<typeof SignUpSchema>;
export type VerifyMobileType = z.infer<typeof VerifyMobileSchema>;
