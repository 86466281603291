import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "@app/store";
import { graphqlClient } from "@app/lib/graphql-client";
import * as cio from "@app/lib/customer-io";

import { getJWTExpiresIn } from "./utils";
import { gql } from "graphql-request";
import {
  AuthenticatedUserFragment,
  LoginWithPasswordDocument,
  LoginWithPasswordMutation,
  LoginWithPasswordMutationVariables,
  RefreshJwtDocument,
  RefreshJwtMutation,
  RefreshJwtMutationVariables,
  RegisterDocument,
  RegisterMutation,
  RegisterMutationVariables,
} from "@app/generated/graphql";
import { fetcher } from "@app/generated/fetcher";
import { isGQLError } from "@app/typings";

async function getCsrfToken() {
  const res = await fetch("/api/auth/csrf");
  const { csrfToken } = await res.json();

  return csrfToken;
}

async function loginV2({ email, password }: { email: string; password: string }) {
  await fetch("/api/auth/callback/credentials", {
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    // @ts-expect-error
    body: new URLSearchParams({
      csrfToken: await getCsrfToken(),
      callbackUrl: window.location.origin,
      email,
      password,
      json: true,
    }),
  });
}

async function logoutV2() {
  await fetch("/api/auth/signout", {
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    // @ts-expect-error
    body: new URLSearchParams({
      csrfToken: await getCsrfToken(),
      callbackUrl: window.location.origin,
      json: true,
    }),
  });
}

export const AUTHENTICATED_USER_FRAGMENT = gql`
  fragment AuthenticatedUserFragment on AuthenticatedUser {
    _id
    avatarURL
    email
    aboutMe
    legalName {
      firstName
      lastName
      verified
    }
    contactNumber {
      phoneNumber
    }
    setup {
      contactNumberVerified
    }
  }
`;

export const LOGIN_WITH_PASSWORD_MUTATION = gql`
  mutation LoginWithPassword($email: String!, $password: String!, $staySignedIn: Boolean!) {
    LoginWithPassword(email: $email, password: $password, staySignedIn: $staySignedIn) {
      __typename

      ... on LoginSuccess {
        access_token
        user {
          ...AuthenticatedUserFragment
        }
      }

      ... on LoginFailure {
        errorCode
        errorMessage
      }
    }
  }
`;

export const REGISTER_MUTATION = gql`
  mutation Register(
    $email: String!
    $password: String!
    $lastName: String!
    $firstName: String!
    $mobileNumber: ContactNumberInput!
    $referrerUserId: String
  ) {
    Register(
      email: $email
      password: $password
      lastName: $lastName
      firstName: $firstName
      mobileNumber: $mobileNumber
      referrerUserId: $referrerUserId
    ) {
      __typename

      ... on RegisterSuccess {
        user {
          ...AuthenticatedUserFragment
        }
        contactNumberId
      }

      ... on GqlError {
        code
        message
      }
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    Logout {
      success
    }
  }
`;

export const REFRESH_JWT_MUTATION = gql`
  mutation RefreshJWT {
    RefreshJWT {
      __typename

      ... on LoginSuccess {
        access_token
        user {
          ...AuthenticatedUserFragment
        }
      }

      ... on LoginFailure {
        errorCode
        errorMessage
      }
    }
  }
`;

export interface AuthState {
  access_token?: string;
  user?: AuthenticatedUserFragment;
  loading: boolean;
}

const initialState: AuthState = {
  access_token: undefined,
  user: undefined,
  loading: true,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Required<Omit<AuthState, "loading">>>) => {
      const { access_token, user } = action.payload;

      state.access_token = access_token;
      state.user = user;

      // Set auth header on our client
      graphqlClient.setHeader("authorization", `Bearer ${access_token}`);

      // Identify the newly set user
      cio.identifyUser(user);
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

const { setUser, setLoading } = authSlice.actions;

/**
 * Redux Thunk used to login a user with email-pass. If successful, this action
 * will also set the user to the store.
 */
export const loginWithPassword =
  (payload: LoginWithPasswordMutationVariables) => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    const { LoginWithPassword: res } = await fetcher<
      LoginWithPasswordMutation,
      LoginWithPasswordMutationVariables
    >(LoginWithPasswordDocument, payload)();

    if (!res || res.__typename === "LoginFailure") {
      throw res;
    }

    await loginV2(payload);
    dispatch(setUser(res));

    // Start optimistic refresher
    dispatch(optimisticTokenRefresher(res.access_token));

    dispatch(setLoading(false));
  };

/**
 * Redux Thunk used to logout a user. This makes a request to the API which
 * unsets the refreshToken HTTP cookie and removes the access token and user
 * from the auth store.
 */
export const logout = () => async () => {
  await fetcher(LOGOUT_MUTATION)();
  await logoutV2();
  window.location.replace("/");
};

/**
 * Redux Thunk used to refresh a user's access token using the refresh token
 * HTTP Cookie. This action should be run on app start-up to restore the user's
 * session.
 *
 * This action will also recursively call itself to optimistically refresh the
 * current session before it expires.
 */
export const refreshToken = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));

  try {
    const { RefreshJWT: res } = await graphqlClient.request<RefreshJwtMutation>(RefreshJwtDocument);

    // If the user session is successfully restored, update the store
    if (res.__typename === "LoginSuccess") {
      dispatch(setUser(res));
    }
  } catch (error) {
    // No-op on invalid refresh token, just assume they're not logged in.
  }

  dispatch(setLoading(false));
};

/**
 * Redux Thunk used to register a user with email-pass. If successful, this
 * action will also dispatch the {@link loginWithPassword} action.
 *
 * This action will also return the contactNumberId that can be used to verify the user's mobile number.
 */
export const registerWithPassword =
  (payload: RegisterMutationVariables) => async (dispatch: AppDispatch) => {
    const { Register: res } = await fetcher<RegisterMutation, RegisterMutationVariables>(
      RegisterDocument,
      payload
    )();

    if (!res || isGQLError(res)) {
      throw res;
    }

    await dispatch(
      loginWithPassword({
        email: payload.email,
        password: payload.password,
        staySignedIn: true,
      })
    );

    return res.contactNumberId;
  };

/**
 * Helper function to initiate an optimistic token refresher.
 */
let refreshTimeout: NodeJS.Timeout;
const optimisticTokenRefresher = (jwt: string) => (dispatch: AppDispatch) => {
  const expiresInMS = getJWTExpiresIn(jwt);

  // Clear existing optimistic refresh timeout if any
  clearTimeout(refreshTimeout);

  // Setup new timeout
  refreshTimeout = setTimeout(() => {
    dispatch(refreshToken());
  }, expiresInMS);
};

/** Selectors */

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectAccessToken = (state: RootState) => state.auth.access_token;
export const selectLoading = (state: RootState) => state.auth.loading;

export default authSlice.reducer;
