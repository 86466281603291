import React from "react";
import tw from "twin.macro";

interface IDatepickerColumnProps {
  type: "from" | "to";
  date?: string;
}

export const DatepickerColumn: React.FC<IDatepickerColumnProps> = ({ type, date, ...props }) => (
  <div {...props}>
    <label tw="text-md pointer-events-none uppercase font-medium">{type}</label>
    <p tw="text-gray-400" css={[date && tw`text-black`]}>
      {date || "Select Date"}
    </p>
  </div>
);
