interface IJWTPayload {
  userId: string;
  iat: number;
  exp: number;
}

/**
 * Decodes a JWT and returns the Payload.
 */
export function decodeJWT(jwt: string) {
  const payload = jwt.split(".")[1];

  if (!payload) {
    throw new Error("Invalid JWT");
  }

  // TODO: Look into performance
  return JSON.parse(decodeURIComponent(escape(window.atob(payload)))) as IJWTPayload;
}

/**
 * Calculates when the token expires, returning the value in milliseconds. The
 * expiry time is also offset by a default value of 1 second.
 */
export function getJWTExpiresIn(jwt: string, offsetSec: number = 1) {
  const { exp } = decodeJWT(jwt);

  const expDate = new Date(exp * 1000);
  const expiresInMS = expDate.getTime() - Date.now();

  return expiresInMS - offsetSec * 1000;
}
