import { logout } from "@app/features/auth/auth-slice";
import { graphqlClient } from "@app/lib/graphql-client";
import { store } from "@app/store";
import { ClientError } from "graphql-request";
import { RefreshJwtDocument, RefreshJwtMutation } from "./graphql";

/**
 * Custom fetcher created for the generated GraphQL hooks.
 */
export const fetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables
): (() => Promise<TData>) => {
  return async () => {
    try {
      return await graphqlClient.request<TData, TVariables>(query, variables);
    } catch (error) {
      const { response } = error as ClientError;
      const errorCode = response.errors?.[0].extensions.code;

      /** Unexpected GQL Error */
      if (errorCode === "INTERNAL_SERVER_ERROR") {
        // genericGQLErrorHandler(response.errors[0].message);
      }

      if (errorCode === "FORBIDDEN") {
        // User is trying to access a forbidden resource, so log them out
        await store.dispatch(logout());
      }

      if (errorCode === "UNAUTHENTICATED" && store.getState().auth.access_token) {
        // Try to refresh the session
        const { RefreshJWT: res } = await graphqlClient.request<RefreshJwtMutation>(
          RefreshJwtDocument
        );

        if (res.__typename === "LoginFailure") {
          // User's session has expired, so log them out
          throw error;
        }

        // Set the new access token and retry the request
        graphqlClient.setHeader("authorization", `Bearer ${res.access_token}`);
        return await graphqlClient.request<TData, TVariables>(query, variables);
      }

      throw error;
    }
  };
};
