import ReactDOM from "react-dom";
import React from "react";
import { useIonModal } from "@ionic/react";

import { useAppDispatch, useAppSelector } from "@app/store";
import { WithContexts } from "@app/lib/WithContexts";
import * as authSlice from "@app/features/auth/auth-slice";
import { AuthForm } from "@app/components/auth";
import { useLocation } from "react-router";

const AuthModalWithContexts = WithContexts(AuthForm);

export interface IUseAuthModalOptions {
  nextPage?: {
    title: string;
    href: string;
  };
}

type AuthFormProps = React.ComponentProps<typeof AuthForm>;

function useAuth() {
  const [nextPage, setNextPage] = React.useState<AuthFormProps["nextPage"]>();
  const [presentModal, dismissModal] = useIonModal(AuthModalWithContexts, {
    nextPage,
    onDismiss: () => dismissModal(),
  } as AuthFormProps);

  function presentAuthModal(options: Pick<AuthFormProps, "nextPage"> = {}) {
    setNextPage(options.nextPage);

    presentModal({
      cssClass: "auth-modal",
      swipeToClose: true,
      presentingElement: document.querySelector("ion-router-outlet")!,
      onDidDismiss: () => {
        ReactDOM.unmountComponentAtNode(document.querySelector("ion-modal > .ion-page")!);
      },
    });
  }

  /** State */

  const user = useAppSelector(authSlice.selectCurrentUser);
  const accessToken = useAppSelector(authSlice.selectAccessToken);
  const loading = useAppSelector(authSlice.selectLoading);

  /** Methods */

  const dispatch = useAppDispatch();

  const registerWithPassword = (args: Parameters<typeof authSlice["registerWithPassword"]>[0]) =>
    dispatch(authSlice.registerWithPassword(args));

  const loginWithPassword = (args: Parameters<typeof authSlice["loginWithPassword"]>[0]) =>
    dispatch(authSlice.loginWithPassword(args));

  const logout = () => dispatch(authSlice.logout());

  return {
    presentAuthModal,
    user,
    accessToken,
    loading,
    registerWithPassword,
    loginWithPassword,
    logout,
  };
}

export default useAuth;
