import React from "react";
import { IonReactRouter } from "@ionic/react-router";
import { QueryClientProvider } from "react-query";
import { Provider } from "react-redux";

import { queryClient, routerHistory } from "@app/App";
import { store } from "@app/store";

/**
 * This is a temp workaround to Ionic's useOverlay issue of mounting overlays
 * outside the main React app.
 *
 * TODO: Remove this once Ionic's overlay functionality is fixed
 */
export const WithContexts = (Comp: React.FC<any>) => (props: any) =>
  (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <IonReactRouter history={routerHistory}>
          <Comp {...props} />
        </IonReactRouter>
      </QueryClientProvider>
    </Provider>
  );
