import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import "twin.macro";

import { FormCallback } from "@app/typings";
import useCooldown from "@app/hooks/useCooldown";
import { CODE_LENGTH, VerifyMobileSchema, VerifyMobileType } from "@app/features/auth/validations";
import { Button, ButtonLink, LabelledInput } from "@app/components/form";

interface IVerifyMobileStep {
  mobileNumber: string;
  onChangeMobile: () => void;
  onSubmit: FormCallback<VerifyMobileType>;
}

const VerifyMobileStep: React.FC<IVerifyMobileStep> = ({
  mobileNumber,
  onChangeMobile,
  onSubmit,
}) => {
  const form = useForm<VerifyMobileType>({
    defaultValues: { code: "" },
    resolver: zodResolver(VerifyMobileSchema),
  });

  const { canRetry, cooldown, incrementAttempts } = useCooldown(3, 30);

  /** Methods */
  async function sendAnotherCode() {
    incrementAttempts();
    console.log("Sending to", mobileNumber);
  }

  return (
    <div tw="flex flex-col gap-y-4 text-center">
      <h4 tw="text-center text-lg font-medium">Complete Sign up</h4>

      <div tw="text-md">
        <p tw="mb-2">
          Enter the {CODE_LENGTH}-digit code sent to your mobile number
          <br />
          <strong>{mobileNumber}</strong>
        </p>

        {/* <p>
          Wrong number? <ButtonLink onClick={onChangeMobile}>Change</ButtonLink>
        </p> */}
      </div>

      {/* Form */}
      <form onSubmit={form.handleSubmit((values) => onSubmit(values, form))}>
        {/* TODO: Make this a proper code input (only accept numbers) */}
        <LabelledInput
          label="Enter Code"
          {...form.register("code")}
          maxLength={CODE_LENGTH}
          type="password"
          isError={!!form.formState.errors.code}
          errorText={form.formState.errors.code?.message}
        />

        <Button
          type="submit"
          disabled={form.watch("code").length !== CODE_LENGTH || form.formState.isSubmitting}
          expand="block"
          tw="col-span-2 mt-4"
        >
          Verify
        </Button>
      </form>

      {/* Resend */}
      <div tw="mt-2 flex flex-col gap-6 text-sm text-center">
        <p>
          Didn't receive your code?{" "}
          <ButtonLink onClick={sendAnotherCode} disabled={cooldown > 0 || !canRetry}>
            Try again
          </ButtonLink>
        </p>

        {cooldown > 0 && (
          <p tw="px-6">
            <strong>New code sent</strong>
            <br />
            You must wait {cooldown} second{cooldown !== 1 && "s"} before you can send another code.
          </p>
        )}

        {!canRetry && (
          <p tw="px-6">
            <strong>Max attemps reached.</strong>
            <br />
            You are not allowed to send any more codes to this number.
          </p>
        )}
      </div>
    </div>
  );
};

export default VerifyMobileStep;
