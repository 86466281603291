import ReactDOM from "react-dom";

// IF STATEMENT IS ONLY FOR DEVELOPMENT SINCE HMR EXECUTES THIS CODE ON EVERY SAVE
if (!customElements.get("react-wc")) {
  /**
   * Define a custome web component used for specific ionic components such as
   * IonNav which don't expose a first-party React implementation.
   *
   * This WC takes a React Component as it's `render` prop and will mount that
   * React Component to itself.
   */
  customElements.define(
    "react-wc",
    class CustomEl extends HTMLElement {
      /**
       * The React FC to render within this custom web-component
       */
      render!: React.FC;

      connectedCallback() {
        const { render: Component } = this;
        ReactDOM.render(<Component />, this);
      }
    }
  );
}
