import { IonPage } from "@ionic/react";
import tw, { styled } from "twin.macro";

import { useLocation } from "react-router";
import { AuthForm, IAuthModalProps } from "@app/components/auth";

const Login: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const nextPage: IAuthModalProps["nextPage"] = {
    href: searchParams.get("callbackUrl") || "/home",
    title: "Home",
  };

  return (
    <IonPage>
      <div tw="flex flex-col h-full items-center justify-between gap-16 bg-white lg:(bg-gray-600 justify-center)">
        <LoginPageWrapper>
          <AuthForm nextPage={nextPage} />
        </LoginPageWrapper>

        {/* Legal */}
        <div
          tw="flex items-baseline gap-4 mb-8 text-sm lg:text-white"
          css={{ a: tw`underline lg:text-white` }}
        >
          <p tw="font-medium">©2021 Releaseit</p>

          <div tw="flex items-baseline gap-x-2">
            <a href="/page/terms-of-use">Terms of Use</a>
            <span>|</span>
            <a href="/page/privacy-policy">Privacy</a>
          </div>
        </div>
      </div>
    </IonPage>
  );
};

//#region Styles

export const LoginPageWrapper = styled.div`
  ${tw`w-full bg-white overflow-hidden lg:(max-width[470px] rounded-lg)`}
`;

//#endregion

export default Login;
