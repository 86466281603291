import React from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { IonIcon } from "@ionic/react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import "twin.macro";

import { FormCallback } from "@app/typings";
import { LoginSchema } from "@app/features/auth/validations";
import { Button, LabelledInput, Checkbox } from "@app/components/form";

import TermsAndConditions from "./TermsAndConditions";
import { Link } from "react-router-dom";

interface ILoginStepProps {
  onSubmit: FormCallback<z.infer<typeof LoginSchema>>;
}

const LoginStep: React.FC<ILoginStepProps> = ({ onSubmit }) => {
  const form = useForm<z.infer<typeof LoginSchema>>({
    resolver: zodResolver(LoginSchema),
    defaultValues: { staySignedIn: true },
  });

  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <div tw="flex flex-col gap-4 text-center">
      <h4 tw="text-center text-lg font-medium">Confirm your password</h4>

      {/* Form */}
      <form
        onSubmit={form.handleSubmit((values) => onSubmit(values, form))}
        tw="flex flex-col gap-3"
      >
        <LabelledInput
          label="Password"
          type={showPassword ? "text" : "password"}
          {...form.register("password")}
          isError={!!form.formState.errors.password}
          errorText={form.formState.errors.password?.message}
          icon={
            <IonIcon
              icon={showPassword ? eyeOffOutline : eyeOutline}
              onClick={() => setShowPassword(!showPassword)}
              tw="cursor-pointer"
            />
          }
        />

        <Button type="submit" expand="block" tw="col-span-2" disabled={form.formState.isSubmitting}>
          Sign In
        </Button>

        <div tw="col-span-2 flex items-center gap-2">
          <Controller
            control={form.control}
            name="staySignedIn"
            render={({ field: { value, onChange, ...field } }) => (
              <Checkbox
                checked={value}
                onIonChange={({ detail: { checked } }) => onChange(checked)}
                {...field}
              />
            )}
          />

          <label htmlFor="staySignedIn" tw="text-sm">
            Stay signed in?
          </label>
        </div>
      </form>

      <TermsAndConditions tw="mt-2" />

      <a href="/reset-password" tw="underline text-sm">
        Forgot password?
      </a>
    </div>
  );
};

export default LoginStep;
