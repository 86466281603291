import "twin.macro";

const TermsAndConditions: React.FC = () => (
  <p tw="text-sm text-center">
    By continuing, you agree to the <a href="/page/terms-of-use">Terms of Use</a> and the
    collection, use and disclosure of your personal information in accordance with our{" "}
    <a href="/page/privacy-policy">Privacy Policy</a> and consent to receiving marketing
    communications from Releaseit.
  </p>
);

export default TermsAndConditions;
