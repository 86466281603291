import React from "react";

/**
 * Custom hook for creating cooldowns between user attempts. The current
 * cooldown timer can be retrieved with `cooldown`, and additional attempts are
 * registered with `incrementAttempts`.
 */
function useCooldown(maxAttemps: number, cooldownInterval: number) {
  const [attempts, setAttempts] = React.useState(0);
  const [cooldown, setCooldown] = React.useState(0);
  const canRetry = attempts <= maxAttemps;

  React.useEffect(
    function countdown() {
      if (cooldown <= 0) return;

      const timeout = setTimeout(() => setCooldown(cooldown - 1), 1000);
      return () => clearTimeout(timeout);
    },
    [cooldown, setCooldown]
  );

  React.useEffect(
    function resetCooldownOnResend() {
      if (canRetry) setCooldown(attempts * cooldownInterval);
    },
    [attempts, canRetry, setCooldown, cooldownInterval]
  );

  function incrementAttempts() {
    setAttempts((s) => s + 1);
  }

  return { canRetry, cooldown, incrementAttempts };
}

export default useCooldown;
