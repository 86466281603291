import { GqlError } from "@app/generated/graphql";
import { UseFormReturn } from "react-hook-form";

export function isGQLError(error: any): error is GqlError {
  const typename = error.__typename as string | undefined;
  const isErrorType = !!typename?.endsWith("Error");

  return isErrorType && error.hasOwnProperty("code") && error.hasOwnProperty("message");
}

export type AssertSuccessResponse<T> = T extends { __typename: "GenericError" }
  ? never
  : NonNullable<T>;

export type FormCallback<T> = (values: T, form: UseFormReturn<T>) => void;

export * from "./INav";
export * from "./ISearchResult";
export * from "./IRentalItem";
