import { ReactComponent as Logo } from "@app/assets/icons/releaseit-green.svg";
import { Button, ButtonLink, Input, Select } from "@app/components/form";
import { useGetNavigationQuery } from "@app/generated/graphql";
import useAuth from "@app/hooks/useAuth";
import { IonIcon, useIonToast } from "@ionic/react";
import { add, personCircleOutline, search as searchIcon } from "ionicons/icons";
import queryString from "query-string";
import React, { useMemo } from "react";
import tw from "twin.macro";
import { CategoryMegaMenu } from "../CategoryMegaMenu";

interface IPromoLink {
  title: string;
  href: string;
}

const DesktopHeader: React.FC<{ className?: string }> = ({ className }) => (
  <div className={className}>
    <SearchBar />
    <MenuAndPromos />
  </div>
);

const SearchBar: React.FC = () => {
  const [searchString, setSearchString] = React.useState("");
  const [categoryFilter, setCategoryFilter] = React.useState("");

  const [presentToast] = useIonToast();

  return (
    <div tw="flex h-20 bg-gray-800 ">
      <div tw="container flex items-center justify-between gap-x-4">
        {/* Logo */}
        <a href="/home">
          <Logo height={36} width={136} />
        </a>

        {/* Search */}
        <form
          tw="flex max-width[800px] w-full border border-black rounded-lg overflow-hidden"
          onSubmit={(e) => {
            e.preventDefault();

            if (searchString.length < 3) {
              presentToast({
                message: "Search must be at least 3 characters.",
                color: "danger",
                duration: 5000,
              });

              return;
            }

            const path = queryString.stringifyUrl({
              url: "/search",
              query: { q: searchString, ...(categoryFilter && { category: categoryFilter }) },
            });

            window.location.replace(path);
          }}
        >
          <Select
            tw="rounded-none border-none"
            onChange={(c) => {
              setCategoryFilter(c.target.value);
            }}
            value={categoryFilter}
          >
            <option value="">All</option>
            <option value="baby-and-kids">Baby &amp; Kids</option>
            <option value="fashion">Fashion</option>
            <option value="furniture">Furniture</option>
            <option value="garden-and-tools">Garden &amp; Tools</option>
            <option value="home">Home</option>
            <option value="office-and-storage">Office &amp; Storage</option>
            <option value="party-and-events">Party &amp; Events</option>
            <option value="sports-and-recreation">Sports &amp; Recreation</option>
            <option value="technology">Technology</option>
            <option value="vehicles">Vehicles</option>
          </Select>

          <Input
            value={searchString}
            placeholder="Search"
            leftIcon={<IonIcon icon={searchIcon} />}
            css={{
              "&": tw`flex-grow rounded-none border-t-0 border-b-0 border-black`,
              input: tw`text-base`,
            }}
            onChange={(e) => setSearchString(e.target.value)}
          />

          <Button expand="full" type="submit">
            <IonIcon slot="start" icon={searchIcon} />
            Search
          </Button>
        </form>
      </div>
    </div>
  );
};

const MenuAndPromos: React.FC = () => {
  const query = useGetNavigationQuery({}, { staleTime: 1000 * 60 * 30 });
  const promoLinks: IPromoLink[] = useMemo(() => {
    return (
      query.data?.Navigation.promoLinks.map((pl) => ({ title: pl.title, href: pl.href })) || []
    );
  }, [query.data]);

  return (
    <div id="header" tw="flex h-12 bg-gray-100 border-b border-gray-200 text-black">
      <div tw="container grid grid-cols-3 gap-x-6">
        {/* Left */}
        <CategoryMegaMenu />

        {/* Middle */}
        <PromoLinks links={promoLinks} />

        {/* Right */}
        <div tw="flex justify-end items-center gap-x-5">
          <Button color="light" size="small" routerLink="/create-listing">
            <IonIcon slot="start" icon={add} style={{ "--ionicon-stroke-width": 40 }} />
            List Item
          </Button>

          <MyAccountButton />
        </div>
      </div>
    </div>
  );
};

export default DesktopHeader;

// TODO: some hrefs are relatice, so <Link> works, but external links are absoulte and <Link> doesn't work
function PromoLinks({ links }: { links: IPromoLink[] }) {
  return (
    <div tw="flex items-center justify-center gap-x-7 text-sm font-normal tracking-normal">
      {links.map((link) => {
        const isExternal = link.href.startsWith("http");

        return isExternal ? (
          <a
            key={link.href}
            href={link.href}
            target="_blank"
            rel="noreferrer"
            tw="text-black hover:text-black"
          >
            {link.title}
          </a>
        ) : (
          <a key={link.href} href={link.href} tw="text-black hover:text-black">
            {link.title}
          </a>
        );
      })}
    </div>
  );
}

function MyAccountButton() {
  const { user, presentAuthModal } = useAuth();

  if (user) {
    return (
      <a tw="flex items-center gap-x-3 text-gray-800! no-underline" href="/my-account">
        <IonIcon icon={personCircleOutline} tw="h-8 w-8" />
        <p tw="text-sm">My Account</p>
      </a>
    );
  }

  return (
    <ButtonLink tw="flex items-center gap-x-3" onClick={() => presentAuthModal()}>
      <IonIcon icon={personCircleOutline} tw="h-8 w-8" />
      <p tw="text-sm">Sign in</p>
    </ButtonLink>
  );
}
