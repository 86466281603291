import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import "twin.macro";

import { FormCallback } from "@app/typings";
import { Button, IButtonProps, LabelledInput } from "@app/components/form";
import { LoginSignUpSchema } from "@app/features/auth/validations";
import { loginUrls } from "@app/features/auth/providers";

import TermsAndConditions from "./TermsAndConditions";

/* Icons */
import { IonIcon } from "@ionic/react";
import facebookLogo from "@app/assets/icons/facebook-logo.svg";
import googleLogo from "@app/assets/icons/google-logo.svg";
import appleLogo from "@app/assets/icons/apple-logo.svg";

interface ILoginSignUpStepProps {
  onSubmit: FormCallback<z.infer<typeof LoginSignUpSchema>>;
}

const LoginSignUpStep: React.FC<ILoginSignUpStepProps> = ({ onSubmit }) => {
  const form = useForm<{ email: string }>({
    resolver: zodResolver(LoginSignUpSchema),
  });

  return (
    <div tw="flex flex-col gap-y-4">
      {/* Top */}
      <div tw="text-center">
        <h4 tw="mb-4 text-lg font-medium">Login or Sign Up</h4>

        <p tw="text-md">To continue, please sign up or log in by entering your email.</p>
      </div>

      {/* Form */}
      <form
        onSubmit={form.handleSubmit((values) => onSubmit(values, form))}
        tw="flex flex-col gap-y-3"
      >
        <LabelledInput
          label="Email"
          type="email"
          {...form.register("email", { required: true })}
          isError={!!form.formState.errors.email}
          errorText={form.formState.errors.email?.message}
        />

        <Button type="submit" expand="block" disabled={form.formState.isSubmitting}>
          Continue
        </Button>
      </form>

      <TermsAndConditions tw="mt-2" />

      {/* Seperator */}
      {/* <div tw="relative">
        <hr tw="border-gray-200" />
        <p tw="absolute text-gray-400 bg-white px-2 left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2">
          OR
        </p>
      </div> */}

      {/* Social Logins */}
      {/* <div tw="flex flex-col gap-y-2 mx-auto width[250px] mt-3">
        <SocialButton logo={facebookLogo}>Continue with Facebook</SocialButton>
        <SocialButton logo={googleLogo} href={loginUrls.google}>
          Continue with Google
        </SocialButton>
        <SocialButton logo={appleLogo}>Continue with Apple</SocialButton>
      </div> */}
    </div>
  );
};

type ISocialButtonProps = IButtonProps & {
  logo: string;
};

const SocialButton: React.FC<ISocialButtonProps> = ({ logo, children, ...props }) => (
  <Button
    color="light"
    css={{
      "--padding-start": 0,
      "--padding-end": 0,
    }}
    {...props}
  >
    <div tw="grid grid-template-columns[1fr 4fr] items-center w-full">
      <IonIcon icon={logo} tw="h-8 w-8 justify-self-center" />
      <p tw="justify-self-start">{children}</p>
    </div>
  </Button>
);

export default LoginSignUpStep;
