import format from "date-fns/format";
import { enAU } from "date-fns/locale";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";

/**
 * Currency Formatter
 */

const DOLLAR_FORMATTER = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const CENTS_FORMATTER = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const CURRENCY_FORMAT_MAPPINGS = {
  dollar: DOLLAR_FORMATTER,
  cents: CENTS_FORMATTER,
} as const;

type CurrencyFormatTypes = keyof typeof CURRENCY_FORMAT_MAPPINGS;

export function formatCurrency(value: number, type: CurrencyFormatTypes = "dollar") {
  return CURRENCY_FORMAT_MAPPINGS[type].format(value);
}

/**
 * Date Formatter
 */

const DATE_FORMAT_MAPPINGS = {
  /**
   * 19/11/2000
   */
  short: "P",

  /**
   * Nov 19, 2000
   */
  medium: "PP",

  /**
   * November 19th, 2000
   */
  long: "PPP",

  /**
   * 2000 November
   */
  month_year: "MMMM yyyy",
} as const;

type DateFormatTypes = keyof typeof DATE_FORMAT_MAPPINGS;

export function formatDate(value: Date, type: DateFormatTypes = "short") {
  return format(value, DATE_FORMAT_MAPPINGS[type], { locale: enAU });
}

/**
 * Phone Number Formatter
 */

const PHONE_NUMBER_UTIL = PhoneNumberUtil.getInstance();

export function formatPhoneNumber(value: number | string) {
  const parsedNumber = PHONE_NUMBER_UTIL.parseAndKeepRawInput(value.toString(), "AU");
  return PHONE_NUMBER_UTIL.format(parsedNumber, PhoneNumberFormat.NATIONAL);
}
