import React from "react";
import { IonIcon, IonicSwiper } from "@ionic/react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import tw, { styled } from "twin.macro";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

import {
  GenericError,
  useCheckAccountExistsQuery,
  useVerifyPhoneNumberMutation,
} from "@app/generated/graphql";
import useAuth, { IUseAuthModalOptions } from "@app/hooks/useAuth";
import { Button } from "@app/components/form";
import { LoginPageWrapper } from "@app/pages/Login";

/** Steps */
import LoginSignUpStep from "./LoginSignUpStep";
import SignUpStep from "./SignUpStep";
import VerifyMobileStep from "./VerifyMobileStep";
import ConfirmationStep from "./ConfirmationStep";
import LoginStep from "./LoginStep";

/** Icons */
import { arrowBackOutline, closeOutline } from "ionicons/icons";
import { ReactComponent as Logo } from "@app/assets/icons/releaseit-green.svg";
import { gql } from "graphql-request";

export const CHECK_ACCOUNT_EXISTS_QUERY = gql`
  query CheckAccountExists($email: String!) {
    CheckAccountExists(email: $email) {
      exists
      provider
    }
  }
`;

export const VERIFY_PHONE_NUMBER_MUTATION = gql`
  mutation VerifyPhoneNumber($verificationCode: String!, $contactNumberId: String!) {
    VerifyPhoneNumber(verificationCode: $verificationCode, contactNumberId: $contactNumberId) {
      __typename

      ... on VerifyPhoneNumberSuccess {
        success
      }

      ... on GenericError {
        errorCode
        errorMessage
      }
    }
  }
`;

SwiperCore.use([IonicSwiper]);

export interface IAuthModalProps {
  /**
   * Optionally set the page to navigate to once the login/sign up is complete.
   */
  nextPage?: IUseAuthModalOptions["nextPage"];

  /**
   * Event handler for when the user dismisses the modal.
   */
  onDismiss?: () => void;
}

const AuthForm: React.FC<IAuthModalProps> = ({ nextPage, onDismiss }) => {
  const { registerWithPassword, loginWithPassword } = useAuth();

  /** Multi-step form */
  const [swiper, setSwiper] = React.useState<SwiperCore>();
  const [step, setStep] = React.useState(0);
  const [flow, setFlow] = React.useState<"SIGN_UP" | "LOGIN">("SIGN_UP");

  /** Form Values */
  const [email, setEmail] = React.useState("");
  const [mobileNumber, setMobileNumber] = React.useState("");

  const [contactNumberId, setContactNumberId] = React.useState("");

  const verifyPhoneNumberMutation = useVerifyPhoneNumberMutation();

  return (
    <div>
      {/* Toolbar */}
      <StyledToolbar>
        <Button
          fill="clear"
          color="dark"
          shape="round"
          onClick={() => {
            swiper?.slidePrev();
          }}
          css={[tw`invisible`, step === 1 && tw`visible`]}
        >
          <IonIcon icon={arrowBackOutline} slot="icon-only" />
        </Button>

        {/* TODO: fix routing issues */}
        <a href="/home" tw="hidden">
          <Logo className="logo" tw="self-center" />
        </a>

        <Button
          fill="clear"
          color="dark"
          shape="round"
          onClick={onDismiss}
          css={[!onDismiss && tw`invisible`]}
        >
          <IonIcon icon={closeOutline} slot="icon-only" />
        </Button>
      </StyledToolbar>

      {/* Form */}
      <StyledSwiper
        onSwiper={setSwiper}
        noSwiping
        noSwipingSelector="*"
        spaceBetween={48}
        onSlideChange={({ activeIndex }) => setStep(activeIndex)}
      >
        {/* Login or Sign Up Step */}
        <SwiperSlide>
          <LoginSignUpStep
            onSubmit={async ({ email }) => {
              email = email.toLowerCase();
              const { CheckAccountExists: res } = await useCheckAccountExistsQuery.fetcher({
                email,
              })();

              setFlow(res!.exists ? "LOGIN" : "SIGN_UP");
              setEmail(email);

              swiper?.slideNext();
            }}
          />
        </SwiperSlide>

        {/* Sign Up Flow */}
        {flow === "SIGN_UP" && (
          <>
            <SwiperSlide key="sign-up-2">
              <SignUpStep
                onSubmit={async (values, form) => {
                  const contactNumberId = await registerWithPassword({
                    email,
                    ...values,
                    mobileNumber: {
                      countryCode: values.countryCode.toString(),
                      mobileNumber: values.mobileNumber,
                    },
                    referrerUserId: new URLSearchParams(window.location.search).get("referrerId"),
                  });

                  setMobileNumber(values.mobileNumber);
                  setContactNumberId(contactNumberId);

                  // Should this be in auth-slice.ts?
                  ReactGA.event({
                    category: "user",
                    action: "registered",
                    nonInteraction: false,
                  });
                  // Should this be in auth-slice.ts?
                  ReactPixel.track("user.registered", {});

                  swiper?.slideNext();
                }}
              />
            </SwiperSlide>

            <SwiperSlide key="sign-up-3">
              <VerifyMobileStep
                mobileNumber={mobileNumber}
                onChangeMobile={() => {
                  // TODO: Change Mobile
                }}
                onSubmit={async ({ code }, form) => {
                  const { VerifyPhoneNumber: res } = await verifyPhoneNumberMutation.mutateAsync({
                    verificationCode: code,
                    contactNumberId,
                  });

                  if (res?.__typename === "GenericError") {
                    form.setError("code", { message: res.errorCode });
                  } else {
                    swiper?.slideNext();
                  }
                }}
              />
            </SwiperSlide>

            <SwiperSlide key="sign-up-4">
              <ConfirmationStep
                nextPage={nextPage}
                onSubmit={() => {
                  if (nextPage) window.location.replace(nextPage.href);
                  onDismiss?.();
                }}
              />
            </SwiperSlide>
          </>
        )}

        {/* Login Flow */}
        {flow === "LOGIN" && (
          <SwiperSlide key="login-2">
            <LoginStep
              onSubmit={async ({ password, staySignedIn }, form) => {
                try {
                  await loginWithPassword({
                    email,
                    password,
                    staySignedIn,
                  });

                  if (nextPage) window.location.replace(nextPage.href);
                  onDismiss?.();
                } catch (error) {
                  const { errorMessage } = error as GenericError;
                  form.setError("password", { message: errorMessage });
                }
              }}
            />
          </SwiperSlide>
        )}
      </StyledSwiper>
    </div>
  );
};

const StyledToolbar = styled("div")`
  --ionicon-stroke-width: 50;

  ${tw`flex justify-between items-center px-2 py-1 border-b border-gray-200 md:border-none`}

  ${Button} {
    ${tw`(h-10 w-10)!`}
  }

  /** Contextual Styles */

  ${() => LoginPageWrapper} & {
    ${tw`bg-gray-800 py-4`}

    ${Button} {
      --ion-color-base: white !important;
    }

    a {
      ${tw`block`}
    }
  }
`;

const StyledSwiper = styled(Swiper)`
  ${tw`pt-8 pb-12 md:pt-0`}

  .swiper-slide > div {
    ${tw`max-width[370px] mx-auto px-4`}
  }

  /** Contextual Styles */

  ${() => LoginPageWrapper} & {
    ${tw`pt-6`}
  }
`;

export default AuthForm;
