import React from "react";
import { IonIcon } from "@ionic/react";
import "twin.macro";

import { IUseAuthModalOptions } from "@app/hooks/useAuth";
import { Button } from "@app/components/form";

/** Icons */
import { checkmark } from "ionicons/icons";

interface IConfirmationStep {
  nextPage: IUseAuthModalOptions["nextPage"];
  onSubmit: () => void;
}

const ConfirmationStep: React.FC<IConfirmationStep> = ({ nextPage, onSubmit }) => (
  <div tw="flex flex-col gap-y-6 text-center">
    <h4 tw="text-center text-lg font-medium">Complete Sign up</h4>

    <p tw="flex items-center justify-center gap-x-2 text-success text-sm">
      <IonIcon icon={checkmark} />
      You have successfully created your account
    </p>

    <Button type="submit" expand="block" tw="col-span-2" onClick={onSubmit}>
      Continue
    </Button>
  </div>
);

export default ConfirmationStep;
