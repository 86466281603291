import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from "react-query";
import { fetcher } from "./fetcher";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type AboutPage = {
  body: Scalars["String"];
  cacheTime: Scalars["Float"];
  heading: Scalars["String"];
};

export type Account = {
  _id: Scalars["String"];
  accountName: Scalars["String"];
  accountType: AccountType;
  avatarUrl: Scalars["String"];
  billing?: Maybe<AccountBilling>;
  business?: Maybe<Business>;
  commission?: Maybe<Scalars["Float"]>;
  createdAt: Scalars["String"];
  enabled: Scalars["Boolean"];
  listingCount: Scalars["Float"];
  listings: Array<Listing>;
  primaryUser: User;
  primaryUserId: Scalars["String"];
  stripeAccountId?: Maybe<Scalars["Boolean"]>;
  users: Array<User>;
};

export type AccountListingsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type AccountBilling = {
  budgetMonthlyCents: Scalars["Float"];
  categoryCpc: Array<CategoryCpc>;
  defaultCpc: Scalars["Float"];
};

export enum AccountType {
  B2c = "b2c",
  C2c = "c2c",
}

export type Attribute = {
  attributeId: Scalars["String"];
  attributeName: Scalars["String"];
  attributeType: AttributeType;
  categoryIds: Array<Scalars["String"]>;
  required?: Maybe<Scalars["Boolean"]>;
  selectOptions: Array<Scalars["String"]>;
};

export enum AttributeType {
  Boolean = "boolean",
  InputFloat = "input_float",
  InputInt = "input_int",
  InputString = "input_string",
  SelectMulti = "select_multi",
  SelectSingle = "select_single",
}

export type AttributeValue = {
  attributeId: Scalars["String"];
  attributeName: Scalars["String"];
  attributeType: AttributeType;
  valueBoolean?: Maybe<Scalars["Boolean"]>;
  valueFloat?: Maybe<Scalars["Float"]>;
  valueInt?: Maybe<Scalars["Float"]>;
  valueString?: Maybe<Scalars["String"]>;
  valueStringArray?: Maybe<Array<Scalars["String"]>>;
};

export type AttributeValueInput = {
  attributeId: Scalars["String"];
  valueBoolean?: InputMaybe<Scalars["Boolean"]>;
  valueFloat?: InputMaybe<Scalars["Float"]>;
  valueInt?: InputMaybe<Scalars["Float"]>;
  valueString?: InputMaybe<Scalars["String"]>;
  valueStringArray?: InputMaybe<Array<Scalars["String"]>>;
};

export type AuthenticatedUser = {
  _id: Scalars["String"];
  aboutMe?: Maybe<Scalars["String"]>;
  account: Account;
  accountId: Scalars["String"];
  accountName: Scalars["String"];
  avatarURL?: Maybe<Scalars["String"]>;
  contactNumber: Array<ContactNumber>;
  createDate: Scalars["DateTime"];
  email: Scalars["String"];
  emailVerified?: Maybe<Scalars["Boolean"]>;
  enabled: Scalars["Boolean"];
  legalName: LegalName;
  setup: Setup;
  superUser: Scalars["Boolean"];
  user: VerifyPhoneNumberResult;
};

export type AvailabilityResponse = {
  available: Scalars["Boolean"];
  endDate: Scalars["String"];
  startDate: Scalars["String"];
  unavailableDates: Array<Scalars["String"]>;
};

export type AvailableCategory2 = {
  categoryId: Scalars["String"];
  categoryName: Scalars["String"];
  depth: Scalars["Float"];
  originalPath: Scalars["String"];
  path: Scalars["String"];
  pathMap: Array<Scalars["String"]>;
  urlSlug: Scalars["String"];
};

export type BookOwnListingError = GqlError & {
  code: Scalars["String"];
  message: Scalars["String"];
};

export type Booking = {
  _id: Scalars["String"];
  checkoutId: Scalars["String"];
  dailyListingPrices: DailyPrices;
  dateCreated: Scalars["DateTime"];
  dateEnd: Scalars["DateTime"];
  dateStart: Scalars["DateTime"];
  listing: Listing;
  listingId: Scalars["String"];
  ownerAccount: Account;
  ownerAccountId: Scalars["String"];
  paymentIntentSecret: Scalars["String"];
  refundableBondPrice: Scalars["Float"];
  seekerUser: User;
  seekerUserId: Scalars["String"];
  status: BookingStatus;
  totalListingPrice: Scalars["Float"];
  totalOfferPrice: Scalars["Float"];
};

export type BookingActionResult = Booking | GenericError;

export type BookingHistoryResult = GenericError | OwnerBookingHistory;

export enum BookingStatus {
  Approved = "approved",
  Cancelled = "cancelled",
  Disputed = "disputed",
  OutForRent = "out_for_rent",
  Paid = "paid",
  PaymentFailed = "payment_failed",
  PendingApproval = "pending_approval",
  Rejected = "rejected",
  Returned = "returned",
}

export type BpExportListingsResponse = {
  csvFileStr: Scalars["String"];
};

export type BpHomepageCategoryTally = {
  category: Category;
  categoryId: Scalars["String"];
  disabledCount: Scalars["Int"];
  enabledCount: Scalars["Int"];
};

export type BpHomepageResponse = {
  categoryTallies: Array<BpHomepageCategoryTally>;
};

export type BpListListingsResponse = {
  page: Array<Listing>;
  totalItems: Scalars["Int"];
};

export type BpOwnerRefExistsResponse = {
  exists: Scalars["Boolean"];
  listingId?: Maybe<Scalars["String"]>;
};

export type BrandResponse = {
  brands: Array<Scalars["String"]>;
};

export type Business = {
  identifier: Scalars["String"];
  name: Scalars["String"];
};

export type BusinessListingReport = {
  clicks?: Maybe<Scalars["Int"]>;
  dateStr: Scalars["String"];
  impressions?: Maybe<Scalars["Int"]>;
  listing: Listing;
  listingId: Scalars["String"];
};

export type Category = {
  /** Ordered list of categories to build a breadcrumb, e.g first item > second > third > etc */
  breadcrumb: Array<CategoryBreadCrumb>;
  category: Category;
  categoryId: Scalars["String"];
  categoryName: Scalars["String"];
  /** Un-ordered list of child categories */
  children: Array<Category>;
  isPrimary: Scalars["Boolean"];
  originalPath: Scalars["String"];
  pathMap: Array<Scalars["String"]>;
  urlSlug: Scalars["String"];
};

export type CategoryBreadCrumb = {
  categoryId: Scalars["String"];
  categoryName: Scalars["String"];
  urlSlug: Scalars["String"];
};

export type CategoryCpc = {
  categoryId: Scalars["String"];
  cpc: Scalars["Float"];
  originalPath: Scalars["String"];
};

export type CategoryCpcInput = {
  categoryId: Scalars["String"];
  cpc: Scalars["Float"];
};

export type CategoryPage = {
  /** Ordered list of categories to build a breadcrumb, e.g first item > second > third > etc */
  breadcrumb: Array<CategoryBreadCrumb>;
  categoryId: Scalars["String"];
  categoryName: Scalars["String"];
  /** Un-ordered list of child categories */
  children: Array<CategoryBreadCrumb>;
  urlSlug: Scalars["String"];
};

export type CategoryResult = Category | GenericError;

export type ChatChannel = {
  booking?: Maybe<Booking>;
  bookingId?: Maybe<Scalars["String"]>;
  ctaText: Scalars["String"];
  isOwner: Scalars["Boolean"];
  isSeeker: Scalars["Boolean"];
  listing: Listing;
  listingId: Scalars["String"];
  seekerUser: User;
  seekerUserId: Scalars["String"];
};

export type ChatChannelResult = ChatChannel | GenericError;

export type ChatToken = {
  token: Scalars["String"];
};

export type ChatTokenResult = ChatToken | GenericError;

export type CheckAccountExistsType = {
  exists: Scalars["Boolean"];
  provider?: Maybe<Providers>;
};

export type Checkout = {
  _id: Scalars["String"];
  dailyListingPrices: Array<Scalars["Float"]>;
  dateEnd: Scalars["DateTime"];
  dateStart: Scalars["DateTime"];
  discount: CheckoutDiscount;
  listing: Listing;
  listingId: Scalars["String"];
  ownerMessage: Scalars["String"];
  /** @deprecated No longer used */
  paymentIntentSecret?: Maybe<Scalars["String"]>;
  refundableBondPrice: Scalars["Float"];
  seekerUser: User;
  seekerUserId: Scalars["String"];
  setupIntentSecret: Scalars["String"];
  totalListingPrice: Scalars["Float"];
  totalOfferPrice: Scalars["Float"];
};

export type CheckoutDiscount = {
  creditCents: Scalars["Float"];
};

export type CompleteCheckoutResult = Booking | ListingUnavailableError;

export enum Condition {
  Fair = "FAIR",
  Good = "GOOD",
  Likenew = "LIKENEW",
}

export type ContactNumber = {
  _id: Scalars["String"];
  countryCode: Scalars["String"];
  nextResendAvailable?: Maybe<Scalars["DateTime"]>;
  phoneNumber: Scalars["String"];
  verificationAttempts: Scalars["Float"];
  verified: Scalars["Boolean"];
};

export type ContactNumberInput = {
  countryCode: Scalars["String"];
  mobileNumber: Scalars["String"];
};

export type ContactPage = {
  body: Scalars["String"];
  cacheTime: Scalars["Float"];
  heading: Scalars["String"];
};

export type CreateCheckoutResult =
  | BookOwnListingError
  | Checkout
  | ListingNotFoundError
  | ListingUnavailableError;

export type CreateListingBatchSuccess = {
  batch: Scalars["Boolean"];
  success: Scalars["Boolean"];
};

export type CreateListingResult = CreateListingBatchSuccess | CreateListingSuccess | GenericError;

export type CreateListingSuccess = {
  listing: Listing;
  success: Scalars["Boolean"];
};

export type CreditHistory = {
  createdOn: Scalars["DateTime"];
  creditCents: Scalars["Float"];
  expiryDate?: Maybe<Scalars["DateTime"]>;
  reason: Scalars["String"];
  reference: Scalars["String"];
};

export type DailyPrices = {
  friday?: Maybe<Scalars["Float"]>;
  monday?: Maybe<Scalars["Float"]>;
  saturday?: Maybe<Scalars["Float"]>;
  sunday?: Maybe<Scalars["Float"]>;
  thursday?: Maybe<Scalars["Float"]>;
  tuesday?: Maybe<Scalars["Float"]>;
  wednesday?: Maybe<Scalars["Float"]>;
};

export enum ENotLiveReason {
  AccountNotApproved = "ACCOUNT_NOT_APPROVED",
  AccountStripeTransfers = "ACCOUNT_STRIPE_TRANSFERS",
  Disabled = "DISABLED",
  NotApproved = "NOT_APPROVED",
  NoImage = "NO_IMAGE",
}

export enum ESuListAccountFilterEnum {
  Disputed = "disputed",
  NeedsEnabling = "needs_enabling",
  NewSignups = "new_signups",
  None = "none",
  Popular = "popular",
}

export type EditListingImageInput = {
  _id: Scalars["String"];
  sortOrder: Scalars["Float"];
};

export type EditListingResult = EditListingSuccess | GenericError;

export type EditListingSuccess = {
  listing: Listing;
};

export type ExternalBankAccount = {
  accountHolderName: Scalars["String"];
  bankAccountId: Scalars["String"];
  bankName: Scalars["String"];
  isPrimary: Scalars["Boolean"];
  last4: Scalars["String"];
};

export type ExternalListing = Listing & {
  _id: Scalars["String"];
  account: Account;
  accountId: Scalars["String"];
  approved: Scalars["Boolean"];
  attributes: Array<AttributeValue>;
  bondPrice: Scalars["Float"];
  brand?: Maybe<Scalars["String"]>;
  category: Category;
  categoryId: Scalars["String"];
  categoryIdPath: Array<Scalars["String"]>;
  description: Scalars["String"];
  enabled: Scalars["Boolean"];
  externalURL?: Maybe<Scalars["String"]>;
  images: Array<ListingImage>;
  keywords: Array<Scalars["String"]>;
  listingId: Scalars["String"];
  listingType: ListingType;
  live: Scalars["Boolean"];
  location: ListingLocation;
  notLiveReason?: Maybe<ENotLiveReason>;
  /** Reference ID from the owner, e.g SKU / Model number */
  ownerRef?: Maybe<Scalars["String"]>;
  ownerUserId: Scalars["String"];
  pricing: Pricing;
  recommendations: Array<ListingRecommendations>;
  title: Scalars["String"];
  unavailableDates: UnavailableDatesResponse;
  user: User;
};

export type GenericError = {
  errorCode: Scalars["String"];
  errorMessage: Scalars["String"];
};

export type GenericResult = GenericError | GenericSuccessType;

export type GenericSuccessType = {
  success: Scalars["Boolean"];
};

export type GetCreditResponse = {
  creditBalance: Scalars["Float"];
  creditHistory: Array<CreditHistory>;
};

export type GqlError = {
  code: Scalars["String"];
  message: Scalars["String"];
};

export type ImageInput = {
  externalImageURL?: InputMaybe<Scalars["String"]>;
  imageToken?: InputMaybe<Scalars["String"]>;
  sortOrder: Scalars["Float"];
};

export type ImageToken = {
  token: Scalars["String"];
};

export type InternalListing = Listing & {
  _id: Scalars["String"];
  account: Account;
  accountId: Scalars["String"];
  approved: Scalars["Boolean"];
  attributes: Array<AttributeValue>;
  bondPrice: Scalars["Float"];
  brand?: Maybe<Scalars["String"]>;
  category: Category;
  categoryId: Scalars["String"];
  categoryIdPath: Array<Scalars["String"]>;
  condition: Condition;
  description: Scalars["String"];
  enabled: Scalars["Boolean"];
  images: Array<ListingImage>;
  keywords: Array<Scalars["String"]>;
  listingId: Scalars["String"];
  listingType: ListingType;
  live: Scalars["Boolean"];
  location: ListingLocation;
  notLiveReason?: Maybe<ENotLiveReason>;
  /** Reference ID from the owner, e.g SKU / Model number */
  ownerRef?: Maybe<Scalars["String"]>;
  ownerUserId: Scalars["String"];
  pricing: Pricing;
  recommendations: Array<ListingRecommendations>;
  rules: Array<Scalars["String"]>;
  title: Scalars["String"];
  unavailableDates: UnavailableDatesResponse;
  user: User;
};

export type LegalName = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  verified: Scalars["Boolean"];
};

export type Listing = {
  _id: Scalars["String"];
  account: Account;
  accountId: Scalars["String"];
  approved: Scalars["Boolean"];
  attributes: Array<AttributeValue>;
  bondPrice: Scalars["Float"];
  brand?: Maybe<Scalars["String"]>;
  category: Category;
  categoryId: Scalars["String"];
  categoryIdPath: Array<Scalars["String"]>;
  description: Scalars["String"];
  enabled: Scalars["Boolean"];
  images: Array<ListingImage>;
  keywords: Array<Scalars["String"]>;
  listingId: Scalars["String"];
  listingType: ListingType;
  live: Scalars["Boolean"];
  location: ListingLocation;
  notLiveReason?: Maybe<ENotLiveReason>;
  /** Reference ID from the owner, e.g SKU / Model number */
  ownerRef?: Maybe<Scalars["String"]>;
  ownerUserId: Scalars["String"];
  pricing: Pricing;
  recommendations: Array<ListingRecommendations>;
  title: Scalars["String"];
  unavailableDates: UnavailableDatesResponse;
  user: User;
};

export type ListingImage = {
  _id: Scalars["String"];
  heroURL?: Maybe<Scalars["String"]>;
  /** Original Image */
  imageURL: Scalars["String"];
  sortOrder: Scalars["Float"];
  thumbnailURL?: Maybe<Scalars["String"]>;
};

export type ListingLocation = {
  latitude?: Maybe<Scalars["Float"]>;
  locality?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["Float"]>;
  national: Scalars["Boolean"];
  postcode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export type ListingLocationInput = {
  latitude?: InputMaybe<Scalars["Float"]>;
  locality?: InputMaybe<Scalars["String"]>;
  longitude?: InputMaybe<Scalars["Float"]>;
  national: Scalars["Boolean"];
  postcode?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
};

export type ListingNotFoundError = GqlError & {
  code: Scalars["String"];
  message: Scalars["String"];
};

export type ListingRecommendations = {
  heading: Scalars["String"];
  listings: Array<Listing>;
};

export enum ListingSortDirection {
  Ascending = "ascending",
  Descending = "descending",
}

export enum ListingSortOrder {
  CreatedOn = "createdOn",
  ModifiedOn = "modifiedOn",
}

export enum ListingType {
  External = "external",
  Internal = "internal",
}

export type ListingUnavailableError = GqlError & {
  code: Scalars["String"];
  message: Scalars["String"];
};

export type ListingsInput = {
  loggedInUser?: InputMaybe<Scalars["Boolean"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type LoginFailure = {
  errorCode: Scalars["String"];
  errorMessage: Scalars["String"];
};

export type LoginResult = LoginFailure | LoginSuccess;

export type LoginSuccess = {
  access_token: Scalars["String"];
  account: Account;
  refresh_token: Scalars["String"];
  user: AuthenticatedUser;
};

export type MigrationInvalidListingsResponse = {
  invalid: Array<MigrationInvalidListingsResponseListing>;
};

export type MigrationInvalidListingsResponseListing = {
  error_message?: Maybe<Scalars["String"]>;
  listingId: Scalars["String"];
};

export type Mutation = {
  AddExternalBankAccount: GenericSuccessType;
  BpDisableListing: Listing;
  BpEnableListing: Listing;
  BpMoveListingCategory: Listing;
  BpMoveListingLocation: Listing;
  CaptureOnboardingInformation: OnboardingResponse;
  ChangeEmailAddress: GenericResult;
  CompleteCheckout: CompleteCheckoutResult;
  CreateChannel: GenericResult;
  CreateCheckout: CreateCheckoutResult;
  CreateExchangeToken: CreateExchangeTokenResponse;
  CreateListing: CreateListingResult;
  CreateSupportRequest: GenericSuccessType;
  DeleteExternalBankAccount: GenericSuccessType;
  ExchangeToken: LoginResult;
  LoginWithPassword: LoginResult;
  Logout: GenericSuccessType;
  MigrationAccountSetBilling: Scalars["Boolean"];
  MigrationInvalidListings: MigrationInvalidListingsResponse;
  MigrationListingLiveStatus: Scalars["Boolean"];
  MigrationUserSuperUser: Scalars["Boolean"];
  OwnerApproveBooking: BookingActionResult;
  OwnerDisputeBooking: BookingActionResult;
  OwnerRejectBooking: BookingActionResult;
  OwnerReleaseBooking: BookingActionResult;
  OwnerReturnBooking: BookingActionResult;
  RefreshJWT: LoginResult;
  Register: RegisterResult;
  RegisterPushNotificationToken: GenericSuccessType;
  RequestPasswordReset: GenericSuccessType;
  RequestPayout: RequestPayoutResult;
  ResendVerificationCode: GenericResult;
  ResetPassword: GenericSuccessType;
  SeekerCancelBooking: BookingActionResult;
  SeekerDisputeBooking: BookingActionResult;
  SetExternalBankAccountAsPrimary: GenericSuccessType;
  SuApproveB2cAccount: Account;
  SuApproveListing: Scalars["Boolean"];
  SuConvertAccountC2c: Scalars["Boolean"];
  SuSwitchActiveAccount: User;
  SuUnApproveListing: Scalars["Boolean"];
  SuUnapproveB2cAccount: Account;
  SuUpdateBilling: Scalars["Boolean"];
  SuVerifyPhoneNumber: Scalars["Boolean"];
  ToggleListing: Scalars["Boolean"];
  UpdateAvatar: UpdateAvatarResult;
  UpdateListing: EditListingResult;
  UpdateMyAccountDetails: Account;
  UpdateUser: AuthenticatedUser;
  UploadIdentityDocuments: OnboardingResponse;
  VerifyEmail: GenericSuccessType;
  VerifyPhoneNumber: VerifyPhoneNumberResult;
};

export type MutationAddExternalBankAccountArgs = {
  bankAccountToken: Scalars["String"];
};

export type MutationBpDisableListingArgs = {
  listingId: Scalars["String"];
};

export type MutationBpEnableListingArgs = {
  listingId: Scalars["String"];
};

export type MutationBpMoveListingCategoryArgs = {
  categoryId: Scalars["String"];
  listingId: Scalars["String"];
};

export type MutationBpMoveListingLocationArgs = {
  listingId: Scalars["String"];
  location: ListingLocationInput;
};

export type MutationCaptureOnboardingInformationArgs = {
  addressLine1: Scalars["String"];
  addressLine2?: InputMaybe<Scalars["String"]>;
  city: Scalars["String"];
  dateOfBirthDay: Scalars["Int"];
  dateOfBirthMonth: Scalars["Int"];
  dateOfBirthYear: Scalars["Int"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  postalCode: Scalars["String"];
  state: Scalars["String"];
};

export type MutationChangeEmailAddressArgs = {
  email: Scalars["String"];
};

export type MutationCompleteCheckoutArgs = {
  checkoutId: Scalars["String"];
  message?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateChannelArgs = {
  listingId: Scalars["String"];
};

export type MutationCreateCheckoutArgs = {
  dateEnd: Scalars["DateTime"];
  dateStart: Scalars["DateTime"];
  listingId: Scalars["String"];
};

export type MutationCreateExchangeTokenArgs = {
  refreshToken: Scalars["String"];
};

export type MutationCreateListingArgs = {
  accountId?: InputMaybe<Scalars["String"]>;
  attributes: Array<AttributeValueInput>;
  batch?: InputMaybe<Scalars["Boolean"]>;
  bondPrice: Scalars["Float"];
  brand?: InputMaybe<Scalars["String"]>;
  categoryId: Scalars["String"];
  condition?: InputMaybe<Condition>;
  description: Scalars["String"];
  externalURL?: InputMaybe<Scalars["String"]>;
  images: Array<ImageInput>;
  keywords: Array<Scalars["String"]>;
  location?: InputMaybe<ListingLocationInput>;
  ownerRef?: InputMaybe<Scalars["String"]>;
  pricing: PricingInput;
  rules?: InputMaybe<Array<Scalars["String"]>>;
  title: Scalars["String"];
};

export type MutationCreateSupportRequestArgs = {
  body: Scalars["String"];
  bookingId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  subject: Scalars["String"];
};

export type MutationDeleteExternalBankAccountArgs = {
  bankAccountId: Scalars["String"];
};

export type MutationExchangeTokenArgs = {
  exchangeToken: Scalars["String"];
};

export type MutationLoginWithPasswordArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
  staySignedIn: Scalars["Boolean"];
};

export type MutationOwnerApproveBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationOwnerDisputeBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationOwnerRejectBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationOwnerReleaseBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationOwnerReturnBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationRefreshJwtArgs = {
  refreshToken?: InputMaybe<Scalars["String"]>;
};

export type MutationRegisterArgs = {
  abn?: InputMaybe<Scalars["String"]>;
  companyName?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  mobileNumber: ContactNumberInput;
  password: Scalars["String"];
  referrerUserId?: InputMaybe<Scalars["String"]>;
};

export type MutationRegisterPushNotificationTokenArgs = {
  token: Scalars["String"];
};

export type MutationRequestPasswordResetArgs = {
  email: Scalars["String"];
};

export type MutationResendVerificationCodeArgs = {
  contactNumberId: Scalars["String"];
};

export type MutationResetPasswordArgs = {
  newPassword: Scalars["String"];
  token: Scalars["String"];
};

export type MutationSeekerCancelBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationSeekerDisputeBookingArgs = {
  bookingId: Scalars["String"];
};

export type MutationSetExternalBankAccountAsPrimaryArgs = {
  bankAccountId: Scalars["String"];
};

export type MutationSuApproveB2cAccountArgs = {
  _id: Scalars["String"];
};

export type MutationSuApproveListingArgs = {
  listingId: Scalars["String"];
};

export type MutationSuConvertAccountC2cArgs = {
  accountId: Scalars["String"];
};

export type MutationSuSwitchActiveAccountArgs = {
  accountId: Scalars["String"];
};

export type MutationSuUnApproveListingArgs = {
  listingId: Scalars["String"];
};

export type MutationSuUnapproveB2cAccountArgs = {
  _id: Scalars["String"];
};

export type MutationSuUpdateBillingArgs = {
  accountId: Scalars["String"];
  budgetMonthlyCents: Scalars["Float"];
  categoryCpc: Array<CategoryCpcInput>;
  defaultCpc: Scalars["Float"];
};

export type MutationSuVerifyPhoneNumberArgs = {
  contactNumberId: Scalars["String"];
  userId: Scalars["String"];
  verified: Scalars["Boolean"];
};

export type MutationToggleListingArgs = {
  listingId: Scalars["String"];
};

export type MutationUpdateAvatarArgs = {
  imageToken: Scalars["String"];
};

export type MutationUpdateListingArgs = {
  accountId?: InputMaybe<Scalars["String"]>;
  attributes: Array<AttributeValueInput>;
  batch?: InputMaybe<Scalars["Boolean"]>;
  bondPrice: Scalars["Float"];
  brand?: InputMaybe<Scalars["String"]>;
  categoryId: Scalars["String"];
  condition?: InputMaybe<Condition>;
  description: Scalars["String"];
  editImages: Array<EditListingImageInput>;
  externalURL?: InputMaybe<Scalars["String"]>;
  keywords: Array<Scalars["String"]>;
  listingId: Scalars["String"];
  location?: InputMaybe<ListingLocationInput>;
  newImages: Array<ImageInput>;
  ownerRef?: InputMaybe<Scalars["String"]>;
  pricing: PricingInput;
  rules?: InputMaybe<Array<Scalars["String"]>>;
  title: Scalars["String"];
};

export type MutationUpdateMyAccountDetailsArgs = {
  abn?: InputMaybe<Scalars["String"]>;
  accountName?: InputMaybe<Scalars["String"]>;
  businessName?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateUserArgs = {
  aboutMe?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
};

export type MutationUploadIdentityDocumentsArgs = {
  back?: InputMaybe<Scalars["String"]>;
  front: Scalars["String"];
};

export type MutationVerifyEmailArgs = {
  token: Scalars["String"];
};

export type MutationVerifyPhoneNumberArgs = {
  contactNumberId: Scalars["String"];
  verificationCode: Scalars["String"];
};

export type MyReferralLink = {
  link: Scalars["String"];
};

export type Navigation = {
  cacheTime: Scalars["Float"];
  /** Array of category and an active listing count */
  categories: Array<NavigationTopCategory>;
  /** Array of footer link columns */
  footer: Array<NavigationFooterColumns>;
  /** Array of promo links for the top navigation */
  promoLinks: Array<NavigationLink>;
};

export type NavigationCategory = {
  /** Categories 1 level down */
  children: Array<NavigationCategory>;
  /** Ordered list of popular child categories (which can be more than 1 level down) by # of listings */
  featured: Array<NavigationLink>;
  href: Scalars["String"];
  title: Scalars["String"];
};

export type NavigationFooterColumns = {
  /** Ordered list of links */
  links: Array<NavigationLink>;
  title: Scalars["String"];
};

export type NavigationLink = {
  href: Scalars["String"];
  title: Scalars["String"];
};

export type NavigationTopCategory = {
  /** Categories 1 level down */
  children: Array<NavigationCategory>;
  /** Ordered list of popular child categories (which can be more than 1 level down) by # of listings */
  featured: Array<NavigationLink>;
  href: Scalars["String"];
  /** Ordered list of popular brands by # of listings */
  popularBrands: Array<NavigationLink>;
  title: Scalars["String"];
};

export type OnboardingErrors = {
  code: Scalars["String"];
  reason: Scalars["String"];
  requirement: Scalars["String"];
};

export type OnboardingLink = {
  url: Scalars["String"];
};

export type OnboardingResponse = {
  errors: Array<OnboardingErrors>;
  profile: StripeProfileInfo;
  status: StripeStatus;
};

export type OwnerBookingHistory = {
  current: Array<Booking>;
  past: Array<Booking>;
  upcoming: Array<Booking>;
};

export type Page = {
  body: Scalars["String"];
  cacheTime: Scalars["Float"];
  canonicalUrl: Scalars["String"];
  heading: Scalars["String"];
  metaDescription: Scalars["String"];
  publishedTime: Scalars["Float"];
  robotsNoFollow: Scalars["Boolean"];
  robotsNoIndex: Scalars["Boolean"];
  title: Scalars["String"];
  updatedTime: Scalars["Float"];
  urlSlug: Scalars["String"];
};

export type Pricing = {
  friday?: Maybe<Scalars["Float"]>;
  monday?: Maybe<Scalars["Float"]>;
  saturday?: Maybe<Scalars["Float"]>;
  sunday?: Maybe<Scalars["Float"]>;
  thursday?: Maybe<Scalars["Float"]>;
  tuesday?: Maybe<Scalars["Float"]>;
  wednesday?: Maybe<Scalars["Float"]>;
};

export type PricingInput = {
  friday?: InputMaybe<Scalars["Float"]>;
  monday?: InputMaybe<Scalars["Float"]>;
  saturday?: InputMaybe<Scalars["Float"]>;
  sunday?: InputMaybe<Scalars["Float"]>;
  thursday?: InputMaybe<Scalars["Float"]>;
  tuesday?: InputMaybe<Scalars["Float"]>;
  wednesday?: InputMaybe<Scalars["Float"]>;
};

export type PricingResponse = {
  bondPrice: Scalars["Int"];
  dailyPrices: DailyPrices;
  endDate: Scalars["String"];
  startDate: Scalars["String"];
  totalPrice: Scalars["Int"];
};

export enum Providers {
  Apple = "apple",
  Facebook = "facebook",
  Google = "google",
  Password = "password",
}

export type Query = {
  AboutPage: AboutPage;
  AvailableCategories: Array<CategoryResult>;
  AvailableCategories2: Array<AvailableCategory2>;
  Booking: Booking;
  BpCategoriesSummary: BpHomepageResponse;
  BpExportCsvListings: BpExportListingsResponse;
  BpGetBookings: Array<Booking>;
  BpListListings: BpListListingsResponse;
  BpOwnerRefExists: BpOwnerRefExistsResponse;
  CalculateBookingPrice: PricingResponse;
  Category: CategoryResult;
  CategoryAttributes: Array<Attribute>;
  CategoryPage?: Maybe<CategoryPage>;
  ChatChannel: ChatChannelResult;
  ChatToken: ChatTokenResult;
  CheckAccountExists: CheckAccountExistsType;
  CheckListingAvailability: AvailabilityResponse;
  CheckOnboardingStatus: OnboardingResponse;
  Checkout?: Maybe<Checkout>;
  CommunityCategories?: Maybe<Array<Category>>;
  ContactPage: ContactPage;
  CreateOnboardingLink: OnboardingLink;
  CreateVerificationSession: VerificationSession;
  FetchBusinessListingReports: Array<BusinessListingReport>;
  GetAccount: Account;
  GetAllExternalBankAccounts: Array<ExternalBankAccount>;
  GetBrandPage: BrandResponse;
  GetBrandSlug?: Maybe<Scalars["String"]>;
  GetCredit: GetCreditResponse;
  GetMyReferralLink: MyReferralLink;
  GetReferralHistory: Array<ReferralHistory>;
  GetStripeBalance: StripeBalance;
  GetStripeTransfersStatus?: Maybe<Scalars["String"]>;
  GetUser: User;
  HomePageRecommendations: Array<ListingRecommendations>;
  Listing?: Maybe<Listing>;
  Listings: Array<Listing>;
  LocalityAutocomplete: Array<ListingLocation>;
  LocalityCsvAutocomplete: ListingLocation;
  Me: AuthenticatedUser;
  Navigation: Navigation;
  OwnerBookingHistory: BookingHistoryResult;
  Page: Page;
  RentalHistory: RentalHistoryResult;
  RequestImageToken: Array<ImageToken>;
  SearchConfig: SearchConfig;
  StorePage?: Maybe<StorePage>;
  SuGetAccount: Account;
  SuListAccounts: SuListAccountsResponse;
  User?: Maybe<User>;
};

export type QueryAvailableCategories2Args = {
  depth: Scalars["Int"];
};

export type QueryBookingArgs = {
  bookingId: Scalars["String"];
};

export type QueryBpCategoriesSummaryArgs = {
  accountId?: InputMaybe<Scalars["String"]>;
  allAccounts?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryBpExportCsvListingsArgs = {
  accountId?: InputMaybe<Scalars["String"]>;
  all_accounts?: InputMaybe<Scalars["Boolean"]>;
  approved?: InputMaybe<Scalars["Boolean"]>;
  categoryId?: InputMaybe<Scalars["String"]>;
  enabled?: InputMaybe<Scalars["Boolean"]>;
  listingType?: InputMaybe<ListingType>;
  live?: InputMaybe<Scalars["Boolean"]>;
  location?: InputMaybe<ListingLocationInput>;
  notLiveReason?: InputMaybe<ENotLiveReason>;
  search_term?: InputMaybe<Scalars["String"]>;
};

export type QueryBpGetBookingsArgs = {
  statuses?: InputMaybe<Array<BookingStatus>>;
};

export type QueryBpListListingsArgs = {
  accountId?: InputMaybe<Scalars["String"]>;
  all_accounts?: InputMaybe<Scalars["Boolean"]>;
  approved?: InputMaybe<Scalars["Boolean"]>;
  categoryId?: InputMaybe<Scalars["String"]>;
  enabled?: InputMaybe<Scalars["Boolean"]>;
  limit: Scalars["Int"];
  listingType?: InputMaybe<ListingType>;
  live?: InputMaybe<Scalars["Boolean"]>;
  location?: InputMaybe<ListingLocationInput>;
  notLiveReason?: InputMaybe<ENotLiveReason>;
  offset: Scalars["Int"];
  search_term?: InputMaybe<Scalars["String"]>;
  sort_direction?: InputMaybe<ListingSortDirection>;
  sort_order?: InputMaybe<ListingSortOrder>;
};

export type QueryBpOwnerRefExistsArgs = {
  accountId: Scalars["String"];
  ignoreListingId?: InputMaybe<Scalars["String"]>;
  ownerRef: Scalars["String"];
};

export type QueryCalculateBookingPriceArgs = {
  endDate: Scalars["String"];
  listingId: Scalars["String"];
  startDate: Scalars["String"];
};

export type QueryCategoryArgs = {
  categoryId: Scalars["String"];
};

export type QueryCategoryAttributesArgs = {
  categoryId: Scalars["String"];
};

export type QueryCategoryPageArgs = {
  categoryId?: InputMaybe<Scalars["String"]>;
  urlSlug?: InputMaybe<Scalars["String"]>;
};

export type QueryChatChannelArgs = {
  channelId: Scalars["String"];
};

export type QueryCheckAccountExistsArgs = {
  email: Scalars["String"];
};

export type QueryCheckListingAvailabilityArgs = {
  endDate: Scalars["DateTime"];
  listingId: Scalars["String"];
  startDate: Scalars["DateTime"];
};

export type QueryCheckoutArgs = {
  _id: Scalars["String"];
};

export type QueryCommunityCategoriesArgs = {
  postcodes: Array<Scalars["String"]>;
};

export type QueryCreateOnboardingLinkArgs = {
  refreshURL: Scalars["String"];
  returnURL: Scalars["String"];
};

export type QueryCreateVerificationSessionArgs = {
  checkoutSessionId?: InputMaybe<Scalars["String"]>;
};

export type QueryFetchBusinessListingReportsArgs = {
  accountId?: InputMaybe<Scalars["String"]>;
  endDate: Scalars["DateTime"];
  startDate: Scalars["DateTime"];
};

export type QueryGetAccountArgs = {
  accountId: Scalars["String"];
};

export type QueryGetBrandPageArgs = {
  slug: Scalars["String"];
};

export type QueryGetBrandSlugArgs = {
  brandName: Scalars["String"];
};

export type QueryGetUserArgs = {
  _id: Scalars["String"];
};

export type QueryListingArgs = {
  listingId: Scalars["String"];
};

export type QueryListingsArgs = {
  filter?: InputMaybe<ListingsInput>;
  limit?: InputMaybe<Scalars["Float"]>;
  skip?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryLocalityAutocompleteArgs = {
  localityPartial: Scalars["String"];
};

export type QueryLocalityCsvAutocompleteArgs = {
  postcode: Scalars["String"];
  suburb?: InputMaybe<Scalars["String"]>;
};

export type QueryPageArgs = {
  urlSlug: Scalars["String"];
};

export type QueryRequestImageTokenArgs = {
  qty?: InputMaybe<Scalars["Int"]>;
};

export type QueryStorePageArgs = {
  accountId: Scalars["String"];
};

export type QuerySuGetAccountArgs = {
  _id: Scalars["String"];
};

export type QuerySuListAccountsArgs = {
  filter: ESuListAccountFilterEnum;
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  search_term?: InputMaybe<Scalars["String"]>;
};

export type QueryUserArgs = {
  userId: Scalars["String"];
};

export type ReferralHistory = {
  createdAt: Scalars["DateTime"];
  creditAmountCents: Scalars["Float"];
  referredUserName: Scalars["String"];
  status: ReferralStatus;
};

export enum ReferralStatus {
  Credited = "credited",
  Expired = "expired",
  Pending = "pending",
}

export type RegisterAccountExistsError = GqlError & {
  code: Scalars["String"];
  message: Scalars["String"];
};

export type RegisterInvalidInputError = GqlError & {
  abnError?: Maybe<Scalars["String"]>;
  code: Scalars["String"];
  companyNameError?: Maybe<Scalars["String"]>;
  emailError?: Maybe<Scalars["String"]>;
  firstNameError?: Maybe<Scalars["String"]>;
  lastNameError?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  mobileNumberError?: Maybe<Scalars["String"]>;
  passwordError?: Maybe<Scalars["String"]>;
};

export type RegisterResult =
  | RegisterAccountExistsError
  | RegisterInvalidInputError
  | RegisterSuccess;

export type RegisterSuccess = {
  contactNumberId: Scalars["String"];
  user: AuthenticatedUser;
};

export type RentalHistory = {
  current: Array<Booking>;
  past: Array<Booking>;
  upcoming: Array<Booking>;
};

export type RentalHistoryResult = GenericError | RentalHistory;

export type RequestPayoutResult = GenericError | RequestPayoutSuccess;

export type RequestPayoutSuccess = {
  arrival_date: Scalars["Float"];
};

export type SearchConfig = {
  account: Scalars["String"];
  collection: Scalars["String"];
};

export type Setup = {
  contactNumberVerified: Scalars["Boolean"];
};

export type StorePage = {
  accountId: Scalars["String"];
  accountName: Scalars["String"];
};

export type StripeBalance = {
  balance: Scalars["Float"];
  pending_balance: Scalars["Float"];
};

export type StripeProfileInfo = {
  addressLine1?: Maybe<Scalars["String"]>;
  addressLine2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  dateOfBirthDay?: Maybe<Scalars["Int"]>;
  dateOfBirthMonth?: Maybe<Scalars["Int"]>;
  dateOfBirthYear?: Maybe<Scalars["Int"]>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  postalCode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export enum StripeStatus {
  Pending = "pending",
  Unverified = "unverified",
  Verified = "verified",
}

export type SuListAccountsResponse = {
  page: Array<Account>;
  totalItems: Scalars["Float"];
};

export type UnavailableDatesResponse = {
  unavailableDates: Array<Scalars["String"]>;
  unavailableDaysOfWeek: Array<Scalars["Int"]>;
};

export type UpdateAvatarResult = GenericError | UpdateAvatarSuccess;

export type UpdateAvatarSuccess = {
  avatarURL: Scalars["String"];
};

export type User = {
  _id: Scalars["String"];
  aboutMe?: Maybe<Scalars["String"]>;
  accountId: Scalars["String"];
  accountName: Scalars["String"];
  avatarURL?: Maybe<Scalars["String"]>;
  contactNumber: Array<ContactNumber>;
  createDate: Scalars["DateTime"];
  email: Scalars["String"];
  legalName: LegalName;
  superUser: Scalars["Boolean"];
  user: VerifyPhoneNumberResult;
};

export type VerificationSession = {
  clientSecret: Scalars["String"];
  url: Scalars["String"];
};

export type VerifyPhoneNumberResult = GenericError | VerifyPhoneNumberSuccess;

export type VerifyPhoneNumberSuccess = {
  success: Scalars["Boolean"];
  user: User;
  userId: Scalars["String"];
};

export type CreateExchangeTokenResponse = {
  exchangeToken: Scalars["String"];
};

export type CheckAccountExistsQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type CheckAccountExistsQuery = {
  CheckAccountExists: { exists: boolean; provider?: Providers | null | undefined };
};

export type VerifyPhoneNumberMutationVariables = Exact<{
  verificationCode: Scalars["String"];
  contactNumberId: Scalars["String"];
}>;

export type VerifyPhoneNumberMutation = {
  VerifyPhoneNumber:
    | { __typename: "GenericError"; errorCode: string; errorMessage: string }
    | { __typename: "VerifyPhoneNumberSuccess"; success: boolean };
};

export type NavigationQueryVariables = Exact<{ [key: string]: never }>;

export type NavigationQuery = {
  Navigation: {
    categories: Array<{
      title: string;
      href: string;
      featured: Array<{ title: string; href: string }>;
      popularBrands: Array<{ title: string; href: string }>;
      children: Array<{
        title: string;
        href: string;
        children: Array<{
          title: string;
          href: string;
          children: Array<{
            title: string;
            href: string;
            featured: Array<{ title: string; href: string }>;
          }>;
          featured: Array<{ title: string; href: string }>;
        }>;
        featured: Array<{ title: string; href: string }>;
      }>;
    }>;
  };
};

export type NavigationCategoryRecursiveFragment = {
  title: string;
  href: string;
  featured: Array<{ title: string; href: string }>;
};

export type GetNavigationQueryVariables = Exact<{ [key: string]: never }>;

export type GetNavigationQuery = {
  Navigation: {
    promoLinks: Array<{ title: string; href: string }>;
    footer: Array<{ title: string; links: Array<{ title: string; href: string }> }>;
    categories: Array<{ title: string; href: string }>;
  };
};

export type GetAvailableCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAvailableCategoriesQuery = {
  AvailableCategories: Array<{
    categoryId: string;
    categoryName: string;
    path: string;
    depth: number;
  }>;
};

export type GetCategoryAttributesQueryVariables = Exact<{
  categoryId: Scalars["String"];
}>;

export type GetCategoryAttributesQuery = {
  CategoryAttributes: Array<{
    attributeId: string;
    attributeType: AttributeType;
    attributeName: string;
    categoryIds: Array<string>;
    selectOptions: Array<string>;
  }>;
};

export type LocalityAutocompleteQueryVariables = Exact<{
  localityPartial: Scalars["String"];
}>;

export type LocalityAutocompleteQuery = {
  LocalityAutocomplete: Array<{
    postcode?: string | null | undefined;
    locality?: string | null | undefined;
    state?: string | null | undefined;
    latitude?: number | null | undefined;
    longitude?: number | null | undefined;
  }>;
};

export type RequestImageTokenQueryVariables = Exact<{
  qty?: Maybe<Scalars["Int"]>;
}>;

export type RequestImageTokenQuery = { RequestImageToken: Array<{ token: string }> };

type ListingItem_Listing_ExternalListing_Fragment = {
  listingId: string;
  title: string;
  enabled: boolean;
  approved: boolean;
  pricing: {
    monday?: number | null | undefined;
    tuesday?: number | null | undefined;
    wednesday?: number | null | undefined;
    thursday?: number | null | undefined;
    friday?: number | null | undefined;
    saturday?: number | null | undefined;
    sunday?: number | null | undefined;
  };
  location: {
    national: boolean;
    locality?: string | null | undefined;
    postcode?: string | null | undefined;
    state?: string | null | undefined;
  };
  images: Array<{ thumbnailURL?: string | null | undefined }>;
};

type ListingItem_Listing_InternalListing_Fragment = {
  listingId: string;
  title: string;
  enabled: boolean;
  approved: boolean;
  pricing: {
    monday?: number | null | undefined;
    tuesday?: number | null | undefined;
    wednesday?: number | null | undefined;
    thursday?: number | null | undefined;
    friday?: number | null | undefined;
    saturday?: number | null | undefined;
    sunday?: number | null | undefined;
  };
  location: {
    national: boolean;
    locality?: string | null | undefined;
    postcode?: string | null | undefined;
    state?: string | null | undefined;
  };
  images: Array<{ thumbnailURL?: string | null | undefined }>;
};

export type ListingItem_ListingFragment =
  | ListingItem_Listing_ExternalListing_Fragment
  | ListingItem_Listing_InternalListing_Fragment;

export type GetAllExternalBankAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllExternalBankAccountsQuery = {
  GetAllExternalBankAccounts: Array<{
    accountHolderName: string;
    bankName: string;
    bankAccountId: string;
    isPrimary: boolean;
    last4: string;
  }>;
};

export type DeleteExternalBankAccountMutationVariables = Exact<{
  bankAccountId: Scalars["String"];
}>;

export type DeleteExternalBankAccountMutation = { DeleteExternalBankAccount: { success: boolean } };

export type SetExternalBankAccountAsPrimaryMutationVariables = Exact<{
  bankAccountId: Scalars["String"];
}>;

export type SetExternalBankAccountAsPrimaryMutation = {
  SetExternalBankAccountAsPrimary: { success: boolean };
};

export type UpdateAvatarMutationVariables = Exact<{
  imageToken: Scalars["String"];
}>;

export type UpdateAvatarMutation = {
  UpdateAvatar:
    | { __typename: "GenericError"; errorMessage: string; errorCode: string }
    | { __typename: "UpdateAvatarSuccess"; avatarURL: string };
};

export type RentalItem_BookingFragment = {
  _id: string;
  dateStart: any;
  dateEnd: any;
  totalOfferPrice: number;
  status: BookingStatus;
  listing:
    | {
        listingId: string;
        title: string;
        images: Array<{ thumbnailURL?: string | null | undefined; sortOrder: number }>;
        location: { locality?: string | null | undefined; postcode?: string | null | undefined };
        user: {
          _id: string;
          avatarURL?: string | null | undefined;
          legalName: { firstName: string; verified: boolean };
        };
      }
    | {
        listingId: string;
        title: string;
        images: Array<{ thumbnailURL?: string | null | undefined; sortOrder: number }>;
        location: { locality?: string | null | undefined; postcode?: string | null | undefined };
        user: {
          _id: string;
          avatarURL?: string | null | undefined;
          legalName: { firstName: string; verified: boolean };
        };
      };
  seekerUser: {
    avatarURL?: string | null | undefined;
    legalName: { firstName: string; verified: boolean; lastName: string };
  };
};

export type AuthenticatedUserFragment = {
  _id: string;
  avatarURL?: string | null | undefined;
  email: string;
  aboutMe?: string | null | undefined;
  legalName: { firstName: string; lastName: string; verified: boolean };
  contactNumber: Array<{ phoneNumber: string }>;
  setup: { contactNumberVerified: boolean };
};

export type LoginWithPasswordMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
  staySignedIn: Scalars["Boolean"];
}>;

export type LoginWithPasswordMutation = {
  LoginWithPassword:
    | { __typename: "LoginFailure"; errorCode: string; errorMessage: string }
    | {
        __typename: "LoginSuccess";
        access_token: string;
        user: {
          _id: string;
          avatarURL?: string | null | undefined;
          email: string;
          aboutMe?: string | null | undefined;
          legalName: { firstName: string; lastName: string; verified: boolean };
          contactNumber: Array<{ phoneNumber: string }>;
          setup: { contactNumberVerified: boolean };
        };
      };
};

export type RegisterMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
  lastName: Scalars["String"];
  firstName: Scalars["String"];
  mobileNumber: ContactNumberInput;
  referrerUserId?: Maybe<Scalars["String"]>;
}>;

export type RegisterMutation = {
  Register:
    | { __typename: "RegisterAccountExistsError"; code: string; message: string }
    | { __typename: "RegisterInvalidInputError"; code: string; message: string }
    | {
        __typename: "RegisterSuccess";
        contactNumberId: string;
        user: {
          _id: string;
          avatarURL?: string | null | undefined;
          email: string;
          aboutMe?: string | null | undefined;
          legalName: { firstName: string; lastName: string; verified: boolean };
          contactNumber: Array<{ phoneNumber: string }>;
          setup: { contactNumberVerified: boolean };
        };
      };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { Logout: { success: boolean } };

export type RefreshJwtMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshJwtMutation = {
  RefreshJWT:
    | { __typename: "LoginFailure"; errorCode: string; errorMessage: string }
    | {
        __typename: "LoginSuccess";
        access_token: string;
        user: {
          _id: string;
          avatarURL?: string | null | undefined;
          email: string;
          aboutMe?: string | null | undefined;
          legalName: { firstName: string; lastName: string; verified: boolean };
          contactNumber: Array<{ phoneNumber: string }>;
          setup: { contactNumberVerified: boolean };
        };
      };
};

export type AboutPageQueryVariables = Exact<{ [key: string]: never }>;

export type AboutPageQuery = { AboutPage: { body: string; heading: string; cacheTime: number } };

export type GetChatTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetChatTokenQuery = {
  ChatToken:
    | { __typename: "ChatToken"; token: string }
    | { __typename: "GenericError"; errorCode: string; errorMessage: string };
};

export type CreateChannelMutationVariables = Exact<{
  listingId: Scalars["String"];
}>;

export type CreateChannelMutation = {
  CreateChannel:
    | { __typename: "GenericError"; errorCode: string; errorMessage: string }
    | { __typename: "GenericSuccessType"; success: boolean };
};

export type ChatChannelQueryVariables = Exact<{
  channelId: Scalars["String"];
}>;

export type ChatChannelQuery = {
  ChatChannel:
    | {
        __typename: "ChatChannel";
        ctaText: string;
        isOwner: boolean;
        isSeeker: boolean;
        listingId: string;
        bookingId?: string | null | undefined;
        listing:
          | {
              listingId: string;
              title: string;
              user: { avatarURL?: string | null | undefined; legalName: { firstName: string } };
              images: Array<{ thumbnailURL?: string | null | undefined }>;
            }
          | {
              listingId: string;
              title: string;
              user: { avatarURL?: string | null | undefined; legalName: { firstName: string } };
              images: Array<{ thumbnailURL?: string | null | undefined }>;
            };
        seekerUser: {
          _id: string;
          createDate: any;
          avatarURL?: string | null | undefined;
          legalName: { firstName: string; lastName: string; verified: boolean };
        };
        booking?: { dateStart: any; dateEnd: any } | null | undefined;
      }
    | { __typename: "GenericError"; errorCode: string; errorMessage: string };
};

export type ContactPageQueryVariables = Exact<{ [key: string]: never }>;

export type ContactPageQuery = {
  ContactPage: { heading: string; body: string; cacheTime: number };
};

export type ContactUsMutationVariables = Exact<{
  email: Scalars["String"];
  subject: Scalars["String"];
  body: Scalars["String"];
}>;

export type ContactUsMutation = { CreateSupportRequest: { success: boolean } };

export type CreateListingMutationVariables = Exact<{
  title: Scalars["String"];
  description: Scalars["String"];
  keywords: Array<Scalars["String"]> | Scalars["String"];
  pricing: PricingInput;
  bondPrice: Scalars["Float"];
  condition: Condition;
  rules: Array<Scalars["String"]> | Scalars["String"];
  images: Array<ImageInput> | ImageInput;
  categoryId: Scalars["String"];
  brand?: Maybe<Scalars["String"]>;
  attributes: Array<AttributeValueInput> | AttributeValueInput;
  location?: Maybe<ListingLocationInput>;
}>;

export type CreateListingMutation = {
  CreateListing:
    | { __typename: "CreateListingBatchSuccess" }
    | { __typename: "CreateListingSuccess"; listing: { listingId: string } | { listingId: string } }
    | { __typename: "GenericError"; errorCode: string; errorMessage: string };
};

export type GetStripeTransfersStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetStripeTransfersStatusQuery = {
  GetStripeTransfersStatus?: string | null | undefined;
};

export type CheckOnboardingStatusQueryVariables = Exact<{ [key: string]: never }>;

export type CheckOnboardingStatusQuery = {
  OnboardingStatus: {
    status: StripeStatus;
    profile: {
      state?: string | null | undefined;
      city?: string | null | undefined;
      postalCode?: string | null | undefined;
      addressLine1?: string | null | undefined;
      dateOfBirthYear?: number | null | undefined;
      dateOfBirthMonth?: number | null | undefined;
      dateOfBirthDay?: number | null | undefined;
      lastName: string;
      firstName: string;
    };
    errors: Array<{ code: string; requirement: string; reason: string }>;
  };
};

export type CreateDisputeMutationVariables = Exact<{
  email: Scalars["String"];
  subject: Scalars["String"];
  body: Scalars["String"];
  bookingId: Scalars["String"];
}>;

export type CreateDisputeMutation = { CreateSupportRequest: { success: boolean } };

export type GetListingPageQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetListingPageQuery = {
  Listing?:
    | { __typename: "ExternalListing" }
    | {
        __typename: "InternalListing";
        listingId: string;
        title: string;
        description: string;
        approved: boolean;
        keywords: Array<string>;
        bondPrice: number;
        brand?: string | null | undefined;
        condition: Condition;
        rules: Array<string>;
        category: {
          categoryId: string;
          categoryName: string;
          urlSlug: string;
          breadcrumb: Array<{ categoryId: string; categoryName: string; urlSlug: string }>;
        };
        pricing: {
          monday?: number | null | undefined;
          tuesday?: number | null | undefined;
          wednesday?: number | null | undefined;
          thursday?: number | null | undefined;
          friday?: number | null | undefined;
          saturday?: number | null | undefined;
          sunday?: number | null | undefined;
        };
        images: Array<{
          _id: string;
          imageURL: string;
          thumbnailURL?: string | null | undefined;
          heroURL?: string | null | undefined;
          sortOrder: number;
        }>;
        user: {
          _id: string;
          accountId: string;
          createDate: any;
          avatarURL?: string | null | undefined;
          legalName: { firstName: string; lastName: string; verified: boolean };
        };
        location: {
          national: boolean;
          postcode?: string | null | undefined;
          locality?: string | null | undefined;
          state?: string | null | undefined;
          latitude?: number | null | undefined;
          longitude?: number | null | undefined;
        };
        attributes: Array<{
          attributeId: string;
          attributeType: AttributeType;
          attributeName: string;
          valueInt?: number | null | undefined;
          valueFloat?: number | null | undefined;
          valueString?: string | null | undefined;
          valueStringArray?: Array<string> | null | undefined;
          valueBoolean?: boolean | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type UpdateListingMutationVariables = Exact<{
  listingId: Scalars["String"];
  title: Scalars["String"];
  brand: Scalars["String"];
  description: Scalars["String"];
  keywords: Array<Scalars["String"]> | Scalars["String"];
  pricing: PricingInput;
  bondPrice: Scalars["Float"];
  condition: Condition;
  rules: Array<Scalars["String"]> | Scalars["String"];
  editImages: Array<EditListingImageInput> | EditListingImageInput;
  newImages: Array<ImageInput> | ImageInput;
  categoryId: Scalars["String"];
  location?: Maybe<ListingLocationInput>;
  attributes: Array<AttributeValueInput> | AttributeValueInput;
}>;

export type UpdateListingMutation = {
  UpdateListing:
    | { __typename: "EditListingSuccess"; listing: { listingId: string } | { listingId: string } }
    | { __typename: "GenericError"; errorCode: string; errorMessage: string };
};

export type GetOwnerBookingHistoryQueryVariables = Exact<{ [key: string]: never }>;

export type GetOwnerBookingHistoryQuery = {
  OwnerBookingHistory:
    | { __typename: "GenericError"; errorCode: string; errorMessage: string }
    | {
        __typename: "OwnerBookingHistory";
        current: Array<{
          _id: string;
          dateStart: any;
          dateEnd: any;
          totalOfferPrice: number;
          status: BookingStatus;
          listing:
            | {
                listingId: string;
                title: string;
                images: Array<{ thumbnailURL?: string | null | undefined; sortOrder: number }>;
                location: {
                  locality?: string | null | undefined;
                  postcode?: string | null | undefined;
                };
                user: {
                  _id: string;
                  avatarURL?: string | null | undefined;
                  legalName: { firstName: string; verified: boolean };
                };
              }
            | {
                listingId: string;
                title: string;
                images: Array<{ thumbnailURL?: string | null | undefined; sortOrder: number }>;
                location: {
                  locality?: string | null | undefined;
                  postcode?: string | null | undefined;
                };
                user: {
                  _id: string;
                  avatarURL?: string | null | undefined;
                  legalName: { firstName: string; verified: boolean };
                };
              };
          seekerUser: {
            avatarURL?: string | null | undefined;
            legalName: { firstName: string; verified: boolean; lastName: string };
          };
        }>;
        upcoming: Array<{
          _id: string;
          dateStart: any;
          dateEnd: any;
          totalOfferPrice: number;
          status: BookingStatus;
          listing:
            | {
                listingId: string;
                title: string;
                images: Array<{ thumbnailURL?: string | null | undefined; sortOrder: number }>;
                location: {
                  locality?: string | null | undefined;
                  postcode?: string | null | undefined;
                };
                user: {
                  _id: string;
                  avatarURL?: string | null | undefined;
                  legalName: { firstName: string; verified: boolean };
                };
              }
            | {
                listingId: string;
                title: string;
                images: Array<{ thumbnailURL?: string | null | undefined; sortOrder: number }>;
                location: {
                  locality?: string | null | undefined;
                  postcode?: string | null | undefined;
                };
                user: {
                  _id: string;
                  avatarURL?: string | null | undefined;
                  legalName: { firstName: string; verified: boolean };
                };
              };
          seekerUser: {
            avatarURL?: string | null | undefined;
            legalName: { firstName: string; verified: boolean; lastName: string };
          };
        }>;
        past: Array<{
          _id: string;
          dateStart: any;
          dateEnd: any;
          totalOfferPrice: number;
          status: BookingStatus;
          listing:
            | {
                listingId: string;
                title: string;
                images: Array<{ thumbnailURL?: string | null | undefined; sortOrder: number }>;
                location: {
                  locality?: string | null | undefined;
                  postcode?: string | null | undefined;
                };
                user: {
                  _id: string;
                  avatarURL?: string | null | undefined;
                  legalName: { firstName: string; verified: boolean };
                };
              }
            | {
                listingId: string;
                title: string;
                images: Array<{ thumbnailURL?: string | null | undefined; sortOrder: number }>;
                location: {
                  locality?: string | null | undefined;
                  postcode?: string | null | undefined;
                };
                user: {
                  _id: string;
                  avatarURL?: string | null | undefined;
                  legalName: { firstName: string; verified: boolean };
                };
              };
          seekerUser: {
            avatarURL?: string | null | undefined;
            legalName: { firstName: string; verified: boolean; lastName: string };
          };
        }>;
      };
};

export type OwnerRejectBookingMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type OwnerRejectBookingMutation = {
  OwnerRejectBooking:
    | { __typename: "Booking" }
    | { __typename: "GenericError"; errorCode: string; errorMessage: string };
};

export type OwnerApproveBookingMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type OwnerApproveBookingMutation = {
  OwnerApproveBooking:
    | { __typename: "Booking" }
    | { __typename: "GenericError"; errorCode: string; errorMessage: string };
};

export type OwnerReleaseBookingMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type OwnerReleaseBookingMutation = {
  OwnerReleaseBooking:
    | { __typename: "Booking" }
    | { __typename: "GenericError"; errorCode: string; errorMessage: string };
};

export type OwnerReturnBookingMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type OwnerReturnBookingMutation = {
  OwnerReturnBooking:
    | { __typename: "Booking" }
    | { __typename: "GenericError"; errorCode: string; errorMessage: string };
};

export type GetUserListingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserListingsQuery = {
  Listings: Array<
    | {
        listingId: string;
        title: string;
        enabled: boolean;
        approved: boolean;
        pricing: {
          monday?: number | null | undefined;
          tuesday?: number | null | undefined;
          wednesday?: number | null | undefined;
          thursday?: number | null | undefined;
          friday?: number | null | undefined;
          saturday?: number | null | undefined;
          sunday?: number | null | undefined;
        };
        location: {
          national: boolean;
          locality?: string | null | undefined;
          postcode?: string | null | undefined;
          state?: string | null | undefined;
        };
        images: Array<{ thumbnailURL?: string | null | undefined }>;
      }
    | {
        listingId: string;
        title: string;
        enabled: boolean;
        approved: boolean;
        pricing: {
          monday?: number | null | undefined;
          tuesday?: number | null | undefined;
          wednesday?: number | null | undefined;
          thursday?: number | null | undefined;
          friday?: number | null | undefined;
          saturday?: number | null | undefined;
          sunday?: number | null | undefined;
        };
        location: {
          national: boolean;
          locality?: string | null | undefined;
          postcode?: string | null | undefined;
          state?: string | null | undefined;
        };
        images: Array<{ thumbnailURL?: string | null | undefined }>;
      }
  >;
};

export type PuaseListingMutationVariables = Exact<{
  listingId: Scalars["String"];
}>;

export type PuaseListingMutation = { ToggleListing: boolean };

export type AddExternalBankAccountMutationVariables = Exact<{
  bankAccountToken: Scalars["String"];
}>;

export type AddExternalBankAccountMutation = { AddExternalBankAccount: { success: boolean } };

export type UpdateUserMutationVariables = Exact<{
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  aboutMe?: Maybe<Scalars["String"]>;
}>;

export type UpdateUserMutation = {
  UpdateUser: {
    _id: string;
    avatarURL?: string | null | undefined;
    email: string;
    aboutMe?: string | null | undefined;
    legalName: { firstName: string; lastName: string; verified: boolean };
    contactNumber: Array<{ phoneNumber: string }>;
    setup: { contactNumberVerified: boolean };
  };
};

export type ChangeEmailAddressMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type ChangeEmailAddressMutation = {
  ChangeEmailAddress:
    | { __typename: "GenericError"; errorCode: string; errorMessage: string }
    | { __typename: "GenericSuccessType"; success: boolean };
};

export type GetRentalHistoryQueryVariables = Exact<{ [key: string]: never }>;

export type GetRentalHistoryQuery = {
  RentalHistory:
    | { __typename: "GenericError"; errorCode: string; errorMessage: string }
    | {
        __typename: "RentalHistory";
        current: Array<{
          _id: string;
          dateStart: any;
          dateEnd: any;
          totalOfferPrice: number;
          status: BookingStatus;
          listing:
            | {
                listingId: string;
                title: string;
                images: Array<{ thumbnailURL?: string | null | undefined; sortOrder: number }>;
                location: {
                  locality?: string | null | undefined;
                  postcode?: string | null | undefined;
                };
                user: {
                  _id: string;
                  avatarURL?: string | null | undefined;
                  legalName: { firstName: string; verified: boolean };
                };
              }
            | {
                listingId: string;
                title: string;
                images: Array<{ thumbnailURL?: string | null | undefined; sortOrder: number }>;
                location: {
                  locality?: string | null | undefined;
                  postcode?: string | null | undefined;
                };
                user: {
                  _id: string;
                  avatarURL?: string | null | undefined;
                  legalName: { firstName: string; verified: boolean };
                };
              };
          seekerUser: {
            avatarURL?: string | null | undefined;
            legalName: { firstName: string; verified: boolean; lastName: string };
          };
        }>;
        upcoming: Array<{
          _id: string;
          dateStart: any;
          dateEnd: any;
          totalOfferPrice: number;
          status: BookingStatus;
          listing:
            | {
                listingId: string;
                title: string;
                images: Array<{ thumbnailURL?: string | null | undefined; sortOrder: number }>;
                location: {
                  locality?: string | null | undefined;
                  postcode?: string | null | undefined;
                };
                user: {
                  _id: string;
                  avatarURL?: string | null | undefined;
                  legalName: { firstName: string; verified: boolean };
                };
              }
            | {
                listingId: string;
                title: string;
                images: Array<{ thumbnailURL?: string | null | undefined; sortOrder: number }>;
                location: {
                  locality?: string | null | undefined;
                  postcode?: string | null | undefined;
                };
                user: {
                  _id: string;
                  avatarURL?: string | null | undefined;
                  legalName: { firstName: string; verified: boolean };
                };
              };
          seekerUser: {
            avatarURL?: string | null | undefined;
            legalName: { firstName: string; verified: boolean; lastName: string };
          };
        }>;
        past: Array<{
          _id: string;
          dateStart: any;
          dateEnd: any;
          totalOfferPrice: number;
          status: BookingStatus;
          listing:
            | {
                listingId: string;
                title: string;
                images: Array<{ thumbnailURL?: string | null | undefined; sortOrder: number }>;
                location: {
                  locality?: string | null | undefined;
                  postcode?: string | null | undefined;
                };
                user: {
                  _id: string;
                  avatarURL?: string | null | undefined;
                  legalName: { firstName: string; verified: boolean };
                };
              }
            | {
                listingId: string;
                title: string;
                images: Array<{ thumbnailURL?: string | null | undefined; sortOrder: number }>;
                location: {
                  locality?: string | null | undefined;
                  postcode?: string | null | undefined;
                };
                user: {
                  _id: string;
                  avatarURL?: string | null | undefined;
                  legalName: { firstName: string; verified: boolean };
                };
              };
          seekerUser: {
            avatarURL?: string | null | undefined;
            legalName: { firstName: string; verified: boolean; lastName: string };
          };
        }>;
      };
};

export type SeekerCancelBookingMutationVariables = Exact<{
  id: Scalars["String"];
}>;

export type SeekerCancelBookingMutation = {
  SeekerCancelBooking:
    | { __typename: "Booking" }
    | { __typename: "GenericError"; errorCode: string; errorMessage: string };
};

export type GetProfileQueryVariables = Exact<{
  userId: Scalars["String"];
}>;

export type GetProfileQuery = {
  User?:
    | {
        accountId: string;
        createDate: any;
        avatarURL?: string | null | undefined;
        aboutMe?: string | null | undefined;
        legalName: { firstName: string; verified: boolean };
      }
    | null
    | undefined;
};

export type ResetPasswordMutationVariables = Exact<{
  newPassword: Scalars["String"];
  token: Scalars["String"];
}>;

export type ResetPasswordMutation = { ResetPassword: { success: boolean } };

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type RequestPasswordResetMutation = { RequestPasswordReset: { success: boolean } };

export type VerifyEmailMutationVariables = Exact<{
  token: Scalars["String"];
}>;

export type VerifyEmailMutation = { VerifyEmail: { success: boolean } };

export const NavigationCategoryRecursiveFragmentDoc = `
    fragment NavigationCategoryRecursive on NavigationCategory {
  title
  href
  featured {
    title
    href
  }
}
    `;
export const ListingItem_ListingFragmentDoc = `
    fragment ListingItem_Listing on Listing {
  listingId
  title
  enabled
  approved
  pricing {
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
  }
  location {
    national
    locality
    postcode
    state
  }
  images {
    thumbnailURL
  }
}
    `;
export const RentalItem_BookingFragmentDoc = `
    fragment RentalItem_Booking on Booking {
  _id
  listing {
    listingId
    title
    images {
      thumbnailURL
      sortOrder
    }
    location {
      locality
      postcode
    }
    user {
      _id
      avatarURL
      legalName {
        firstName
        verified
      }
    }
  }
  seekerUser {
    avatarURL
    legalName {
      firstName
      verified
      lastName
    }
  }
  dateStart
  dateEnd
  totalOfferPrice
  status
}
    `;
export const AuthenticatedUserFragmentDoc = `
    fragment AuthenticatedUserFragment on AuthenticatedUser {
  _id
  avatarURL
  email
  aboutMe
  legalName {
    firstName
    lastName
    verified
  }
  contactNumber {
    phoneNumber
  }
  setup {
    contactNumberVerified
  }
}
    `;
export const CheckAccountExistsDocument = `
    query CheckAccountExists($email: String!) {
  CheckAccountExists(email: $email) {
    exists
    provider
  }
}
    `;
export const useCheckAccountExistsQuery = <TData = CheckAccountExistsQuery, TError = unknown>(
  variables: CheckAccountExistsQueryVariables,
  options?: UseQueryOptions<CheckAccountExistsQuery, TError, TData>
) =>
  useQuery<CheckAccountExistsQuery, TError, TData>(
    ["CheckAccountExists", variables],
    fetcher<CheckAccountExistsQuery, CheckAccountExistsQueryVariables>(
      CheckAccountExistsDocument,
      variables
    ),
    options
  );

useCheckAccountExistsQuery.getKey = (variables: CheckAccountExistsQueryVariables) => [
  "CheckAccountExists",
  variables,
];
useCheckAccountExistsQuery.fetcher = (variables: CheckAccountExistsQueryVariables) =>
  fetcher<CheckAccountExistsQuery, CheckAccountExistsQueryVariables>(
    CheckAccountExistsDocument,
    variables
  );
export const VerifyPhoneNumberDocument = `
    mutation VerifyPhoneNumber($verificationCode: String!, $contactNumberId: String!) {
  VerifyPhoneNumber(
    verificationCode: $verificationCode
    contactNumberId: $contactNumberId
  ) {
    __typename
    ... on VerifyPhoneNumberSuccess {
      success
    }
    ... on GenericError {
      errorCode
      errorMessage
    }
  }
}
    `;
export const useVerifyPhoneNumberMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    VerifyPhoneNumberMutation,
    TError,
    VerifyPhoneNumberMutationVariables,
    TContext
  >
) =>
  useMutation<VerifyPhoneNumberMutation, TError, VerifyPhoneNumberMutationVariables, TContext>(
    "VerifyPhoneNumber",
    (variables?: VerifyPhoneNumberMutationVariables) =>
      fetcher<VerifyPhoneNumberMutation, VerifyPhoneNumberMutationVariables>(
        VerifyPhoneNumberDocument,
        variables
      )(),
    options
  );
useVerifyPhoneNumberMutation.fetcher = (variables: VerifyPhoneNumberMutationVariables) =>
  fetcher<VerifyPhoneNumberMutation, VerifyPhoneNumberMutationVariables>(
    VerifyPhoneNumberDocument,
    variables
  );
export const NavigationDocument = `
    query Navigation {
  Navigation {
    categories {
      title
      href
      featured {
        title
        href
      }
      popularBrands {
        title
        href
      }
      children {
        ...NavigationCategoryRecursive
        children {
          ...NavigationCategoryRecursive
          children {
            ...NavigationCategoryRecursive
          }
        }
      }
    }
  }
}
    ${NavigationCategoryRecursiveFragmentDoc}`;
export const useNavigationQuery = <TData = NavigationQuery, TError = unknown>(
  variables?: NavigationQueryVariables,
  options?: UseQueryOptions<NavigationQuery, TError, TData>
) =>
  useQuery<NavigationQuery, TError, TData>(
    variables === undefined ? ["Navigation"] : ["Navigation", variables],
    fetcher<NavigationQuery, NavigationQueryVariables>(NavigationDocument, variables),
    options
  );

useNavigationQuery.getKey = (variables?: NavigationQueryVariables) =>
  variables === undefined ? ["Navigation"] : ["Navigation", variables];
useNavigationQuery.fetcher = (variables?: NavigationQueryVariables) =>
  fetcher<NavigationQuery, NavigationQueryVariables>(NavigationDocument, variables);
export const GetNavigationDocument = `
    query GetNavigation {
  Navigation {
    promoLinks {
      title
      href
    }
    footer {
      title
      links {
        title
        href
      }
    }
    categories {
      title
      href
    }
  }
}
    `;
export const useGetNavigationQuery = <TData = GetNavigationQuery, TError = unknown>(
  variables?: GetNavigationQueryVariables,
  options?: UseQueryOptions<GetNavigationQuery, TError, TData>
) =>
  useQuery<GetNavigationQuery, TError, TData>(
    variables === undefined ? ["GetNavigation"] : ["GetNavigation", variables],
    fetcher<GetNavigationQuery, GetNavigationQueryVariables>(GetNavigationDocument, variables),
    options
  );

useGetNavigationQuery.getKey = (variables?: GetNavigationQueryVariables) =>
  variables === undefined ? ["GetNavigation"] : ["GetNavigation", variables];
useGetNavigationQuery.fetcher = (variables?: GetNavigationQueryVariables) =>
  fetcher<GetNavigationQuery, GetNavigationQueryVariables>(GetNavigationDocument, variables);
export const GetAvailableCategoriesDocument = `
    query GetAvailableCategories {
  AvailableCategories: AvailableCategories2(depth: 3) {
    categoryId
    categoryName
    path
    depth
  }
}
    `;
export const useGetAvailableCategoriesQuery = <
  TData = GetAvailableCategoriesQuery,
  TError = unknown
>(
  variables?: GetAvailableCategoriesQueryVariables,
  options?: UseQueryOptions<GetAvailableCategoriesQuery, TError, TData>
) =>
  useQuery<GetAvailableCategoriesQuery, TError, TData>(
    variables === undefined ? ["GetAvailableCategories"] : ["GetAvailableCategories", variables],
    fetcher<GetAvailableCategoriesQuery, GetAvailableCategoriesQueryVariables>(
      GetAvailableCategoriesDocument,
      variables
    ),
    options
  );

useGetAvailableCategoriesQuery.getKey = (variables?: GetAvailableCategoriesQueryVariables) =>
  variables === undefined ? ["GetAvailableCategories"] : ["GetAvailableCategories", variables];
useGetAvailableCategoriesQuery.fetcher = (variables?: GetAvailableCategoriesQueryVariables) =>
  fetcher<GetAvailableCategoriesQuery, GetAvailableCategoriesQueryVariables>(
    GetAvailableCategoriesDocument,
    variables
  );
export const GetCategoryAttributesDocument = `
    query GetCategoryAttributes($categoryId: String!) {
  CategoryAttributes(categoryId: $categoryId) {
    attributeId
    attributeType
    attributeName
    categoryIds
    selectOptions
  }
}
    `;
export const useGetCategoryAttributesQuery = <TData = GetCategoryAttributesQuery, TError = unknown>(
  variables: GetCategoryAttributesQueryVariables,
  options?: UseQueryOptions<GetCategoryAttributesQuery, TError, TData>
) =>
  useQuery<GetCategoryAttributesQuery, TError, TData>(
    ["GetCategoryAttributes", variables],
    fetcher<GetCategoryAttributesQuery, GetCategoryAttributesQueryVariables>(
      GetCategoryAttributesDocument,
      variables
    ),
    options
  );

useGetCategoryAttributesQuery.getKey = (variables: GetCategoryAttributesQueryVariables) => [
  "GetCategoryAttributes",
  variables,
];
useGetCategoryAttributesQuery.fetcher = (variables: GetCategoryAttributesQueryVariables) =>
  fetcher<GetCategoryAttributesQuery, GetCategoryAttributesQueryVariables>(
    GetCategoryAttributesDocument,
    variables
  );
export const LocalityAutocompleteDocument = `
    query LocalityAutocomplete($localityPartial: String!) {
  LocalityAutocomplete(localityPartial: $localityPartial) {
    postcode
    locality
    state
    latitude
    longitude
  }
}
    `;
export const useLocalityAutocompleteQuery = <TData = LocalityAutocompleteQuery, TError = unknown>(
  variables: LocalityAutocompleteQueryVariables,
  options?: UseQueryOptions<LocalityAutocompleteQuery, TError, TData>
) =>
  useQuery<LocalityAutocompleteQuery, TError, TData>(
    ["LocalityAutocomplete", variables],
    fetcher<LocalityAutocompleteQuery, LocalityAutocompleteQueryVariables>(
      LocalityAutocompleteDocument,
      variables
    ),
    options
  );

useLocalityAutocompleteQuery.getKey = (variables: LocalityAutocompleteQueryVariables) => [
  "LocalityAutocomplete",
  variables,
];
useLocalityAutocompleteQuery.fetcher = (variables: LocalityAutocompleteQueryVariables) =>
  fetcher<LocalityAutocompleteQuery, LocalityAutocompleteQueryVariables>(
    LocalityAutocompleteDocument,
    variables
  );
export const RequestImageTokenDocument = `
    query RequestImageToken($qty: Int) {
  RequestImageToken(qty: $qty) {
    token
  }
}
    `;
export const useRequestImageTokenQuery = <TData = RequestImageTokenQuery, TError = unknown>(
  variables?: RequestImageTokenQueryVariables,
  options?: UseQueryOptions<RequestImageTokenQuery, TError, TData>
) =>
  useQuery<RequestImageTokenQuery, TError, TData>(
    variables === undefined ? ["RequestImageToken"] : ["RequestImageToken", variables],
    fetcher<RequestImageTokenQuery, RequestImageTokenQueryVariables>(
      RequestImageTokenDocument,
      variables
    ),
    options
  );

useRequestImageTokenQuery.getKey = (variables?: RequestImageTokenQueryVariables) =>
  variables === undefined ? ["RequestImageToken"] : ["RequestImageToken", variables];
useRequestImageTokenQuery.fetcher = (variables?: RequestImageTokenQueryVariables) =>
  fetcher<RequestImageTokenQuery, RequestImageTokenQueryVariables>(
    RequestImageTokenDocument,
    variables
  );
export const GetAllExternalBankAccountsDocument = `
    query GetAllExternalBankAccounts {
  GetAllExternalBankAccounts {
    accountHolderName
    bankName
    bankAccountId
    isPrimary
    last4
  }
}
    `;
export const useGetAllExternalBankAccountsQuery = <
  TData = GetAllExternalBankAccountsQuery,
  TError = unknown
>(
  variables?: GetAllExternalBankAccountsQueryVariables,
  options?: UseQueryOptions<GetAllExternalBankAccountsQuery, TError, TData>
) =>
  useQuery<GetAllExternalBankAccountsQuery, TError, TData>(
    variables === undefined
      ? ["GetAllExternalBankAccounts"]
      : ["GetAllExternalBankAccounts", variables],
    fetcher<GetAllExternalBankAccountsQuery, GetAllExternalBankAccountsQueryVariables>(
      GetAllExternalBankAccountsDocument,
      variables
    ),
    options
  );

useGetAllExternalBankAccountsQuery.getKey = (
  variables?: GetAllExternalBankAccountsQueryVariables
) =>
  variables === undefined
    ? ["GetAllExternalBankAccounts"]
    : ["GetAllExternalBankAccounts", variables];
useGetAllExternalBankAccountsQuery.fetcher = (
  variables?: GetAllExternalBankAccountsQueryVariables
) =>
  fetcher<GetAllExternalBankAccountsQuery, GetAllExternalBankAccountsQueryVariables>(
    GetAllExternalBankAccountsDocument,
    variables
  );
export const DeleteExternalBankAccountDocument = `
    mutation DeleteExternalBankAccount($bankAccountId: String!) {
  DeleteExternalBankAccount(bankAccountId: $bankAccountId) {
    success
  }
}
    `;
export const useDeleteExternalBankAccountMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteExternalBankAccountMutation,
    TError,
    DeleteExternalBankAccountMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteExternalBankAccountMutation,
    TError,
    DeleteExternalBankAccountMutationVariables,
    TContext
  >(
    "DeleteExternalBankAccount",
    (variables?: DeleteExternalBankAccountMutationVariables) =>
      fetcher<DeleteExternalBankAccountMutation, DeleteExternalBankAccountMutationVariables>(
        DeleteExternalBankAccountDocument,
        variables
      )(),
    options
  );
useDeleteExternalBankAccountMutation.fetcher = (
  variables: DeleteExternalBankAccountMutationVariables
) =>
  fetcher<DeleteExternalBankAccountMutation, DeleteExternalBankAccountMutationVariables>(
    DeleteExternalBankAccountDocument,
    variables
  );
export const SetExternalBankAccountAsPrimaryDocument = `
    mutation SetExternalBankAccountAsPrimary($bankAccountId: String!) {
  SetExternalBankAccountAsPrimary(bankAccountId: $bankAccountId) {
    success
  }
}
    `;
export const useSetExternalBankAccountAsPrimaryMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SetExternalBankAccountAsPrimaryMutation,
    TError,
    SetExternalBankAccountAsPrimaryMutationVariables,
    TContext
  >
) =>
  useMutation<
    SetExternalBankAccountAsPrimaryMutation,
    TError,
    SetExternalBankAccountAsPrimaryMutationVariables,
    TContext
  >(
    "SetExternalBankAccountAsPrimary",
    (variables?: SetExternalBankAccountAsPrimaryMutationVariables) =>
      fetcher<
        SetExternalBankAccountAsPrimaryMutation,
        SetExternalBankAccountAsPrimaryMutationVariables
      >(SetExternalBankAccountAsPrimaryDocument, variables)(),
    options
  );
useSetExternalBankAccountAsPrimaryMutation.fetcher = (
  variables: SetExternalBankAccountAsPrimaryMutationVariables
) =>
  fetcher<
    SetExternalBankAccountAsPrimaryMutation,
    SetExternalBankAccountAsPrimaryMutationVariables
  >(SetExternalBankAccountAsPrimaryDocument, variables);
export const UpdateAvatarDocument = `
    mutation UpdateAvatar($imageToken: String!) {
  UpdateAvatar(imageToken: $imageToken) {
    __typename
    ... on UpdateAvatarSuccess {
      avatarURL
    }
    ... on GenericError {
      errorMessage
      errorCode
    }
  }
}
    `;
export const useUpdateAvatarMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateAvatarMutation,
    TError,
    UpdateAvatarMutationVariables,
    TContext
  >
) =>
  useMutation<UpdateAvatarMutation, TError, UpdateAvatarMutationVariables, TContext>(
    "UpdateAvatar",
    (variables?: UpdateAvatarMutationVariables) =>
      fetcher<UpdateAvatarMutation, UpdateAvatarMutationVariables>(
        UpdateAvatarDocument,
        variables
      )(),
    options
  );
useUpdateAvatarMutation.fetcher = (variables: UpdateAvatarMutationVariables) =>
  fetcher<UpdateAvatarMutation, UpdateAvatarMutationVariables>(UpdateAvatarDocument, variables);
export const LoginWithPasswordDocument = `
    mutation LoginWithPassword($email: String!, $password: String!, $staySignedIn: Boolean!) {
  LoginWithPassword(
    email: $email
    password: $password
    staySignedIn: $staySignedIn
  ) {
    __typename
    ... on LoginSuccess {
      access_token
      user {
        ...AuthenticatedUserFragment
      }
    }
    ... on LoginFailure {
      errorCode
      errorMessage
    }
  }
}
    ${AuthenticatedUserFragmentDoc}`;
export const useLoginWithPasswordMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    LoginWithPasswordMutation,
    TError,
    LoginWithPasswordMutationVariables,
    TContext
  >
) =>
  useMutation<LoginWithPasswordMutation, TError, LoginWithPasswordMutationVariables, TContext>(
    "LoginWithPassword",
    (variables?: LoginWithPasswordMutationVariables) =>
      fetcher<LoginWithPasswordMutation, LoginWithPasswordMutationVariables>(
        LoginWithPasswordDocument,
        variables
      )(),
    options
  );
useLoginWithPasswordMutation.fetcher = (variables: LoginWithPasswordMutationVariables) =>
  fetcher<LoginWithPasswordMutation, LoginWithPasswordMutationVariables>(
    LoginWithPasswordDocument,
    variables
  );
export const RegisterDocument = `
    mutation Register($email: String!, $password: String!, $lastName: String!, $firstName: String!, $mobileNumber: ContactNumberInput!, $referrerUserId: String) {
  Register(
    email: $email
    password: $password
    lastName: $lastName
    firstName: $firstName
    mobileNumber: $mobileNumber
    referrerUserId: $referrerUserId
  ) {
    __typename
    ... on RegisterSuccess {
      user {
        ...AuthenticatedUserFragment
      }
      contactNumberId
    }
    ... on GqlError {
      code
      message
    }
  }
}
    ${AuthenticatedUserFragmentDoc}`;
export const useRegisterMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<RegisterMutation, TError, RegisterMutationVariables, TContext>
) =>
  useMutation<RegisterMutation, TError, RegisterMutationVariables, TContext>(
    "Register",
    (variables?: RegisterMutationVariables) =>
      fetcher<RegisterMutation, RegisterMutationVariables>(RegisterDocument, variables)(),
    options
  );
useRegisterMutation.fetcher = (variables: RegisterMutationVariables) =>
  fetcher<RegisterMutation, RegisterMutationVariables>(RegisterDocument, variables);
export const LogoutDocument = `
    mutation Logout {
  Logout {
    success
  }
}
    `;
export const useLogoutMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<LogoutMutation, TError, LogoutMutationVariables, TContext>
) =>
  useMutation<LogoutMutation, TError, LogoutMutationVariables, TContext>(
    "Logout",
    (variables?: LogoutMutationVariables) =>
      fetcher<LogoutMutation, LogoutMutationVariables>(LogoutDocument, variables)(),
    options
  );
useLogoutMutation.fetcher = (variables?: LogoutMutationVariables) =>
  fetcher<LogoutMutation, LogoutMutationVariables>(LogoutDocument, variables);
export const RefreshJwtDocument = `
    mutation RefreshJWT {
  RefreshJWT {
    __typename
    ... on LoginSuccess {
      access_token
      user {
        ...AuthenticatedUserFragment
      }
    }
    ... on LoginFailure {
      errorCode
      errorMessage
    }
  }
}
    ${AuthenticatedUserFragmentDoc}`;
export const useRefreshJwtMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<RefreshJwtMutation, TError, RefreshJwtMutationVariables, TContext>
) =>
  useMutation<RefreshJwtMutation, TError, RefreshJwtMutationVariables, TContext>(
    "RefreshJWT",
    (variables?: RefreshJwtMutationVariables) =>
      fetcher<RefreshJwtMutation, RefreshJwtMutationVariables>(RefreshJwtDocument, variables)(),
    options
  );
useRefreshJwtMutation.fetcher = (variables?: RefreshJwtMutationVariables) =>
  fetcher<RefreshJwtMutation, RefreshJwtMutationVariables>(RefreshJwtDocument, variables);
export const AboutPageDocument = `
    query AboutPage {
  AboutPage {
    body
    heading
    cacheTime
  }
}
    `;
export const useAboutPageQuery = <TData = AboutPageQuery, TError = unknown>(
  variables?: AboutPageQueryVariables,
  options?: UseQueryOptions<AboutPageQuery, TError, TData>
) =>
  useQuery<AboutPageQuery, TError, TData>(
    variables === undefined ? ["AboutPage"] : ["AboutPage", variables],
    fetcher<AboutPageQuery, AboutPageQueryVariables>(AboutPageDocument, variables),
    options
  );

useAboutPageQuery.getKey = (variables?: AboutPageQueryVariables) =>
  variables === undefined ? ["AboutPage"] : ["AboutPage", variables];
useAboutPageQuery.fetcher = (variables?: AboutPageQueryVariables) =>
  fetcher<AboutPageQuery, AboutPageQueryVariables>(AboutPageDocument, variables);
export const GetChatTokenDocument = `
    query GetChatToken {
  ChatToken {
    __typename
    ... on ChatToken {
      token
    }
    ... on GenericError {
      errorCode
      errorMessage
    }
  }
}
    `;
export const useGetChatTokenQuery = <TData = GetChatTokenQuery, TError = unknown>(
  variables?: GetChatTokenQueryVariables,
  options?: UseQueryOptions<GetChatTokenQuery, TError, TData>
) =>
  useQuery<GetChatTokenQuery, TError, TData>(
    variables === undefined ? ["GetChatToken"] : ["GetChatToken", variables],
    fetcher<GetChatTokenQuery, GetChatTokenQueryVariables>(GetChatTokenDocument, variables),
    options
  );

useGetChatTokenQuery.getKey = (variables?: GetChatTokenQueryVariables) =>
  variables === undefined ? ["GetChatToken"] : ["GetChatToken", variables];
useGetChatTokenQuery.fetcher = (variables?: GetChatTokenQueryVariables) =>
  fetcher<GetChatTokenQuery, GetChatTokenQueryVariables>(GetChatTokenDocument, variables);
export const CreateChannelDocument = `
    mutation CreateChannel($listingId: String!) {
  CreateChannel(listingId: $listingId) {
    __typename
    ... on GenericSuccessType {
      success
    }
    ... on GenericError {
      errorCode
      errorMessage
    }
  }
}
    `;
export const useCreateChannelMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateChannelMutation,
    TError,
    CreateChannelMutationVariables,
    TContext
  >
) =>
  useMutation<CreateChannelMutation, TError, CreateChannelMutationVariables, TContext>(
    "CreateChannel",
    (variables?: CreateChannelMutationVariables) =>
      fetcher<CreateChannelMutation, CreateChannelMutationVariables>(
        CreateChannelDocument,
        variables
      )(),
    options
  );
useCreateChannelMutation.fetcher = (variables: CreateChannelMutationVariables) =>
  fetcher<CreateChannelMutation, CreateChannelMutationVariables>(CreateChannelDocument, variables);
export const ChatChannelDocument = `
    query ChatChannel($channelId: String!) {
  ChatChannel(channelId: $channelId) {
    __typename
    ... on ChatChannel {
      ctaText
      isOwner
      isSeeker
      listingId
      listing {
        listingId
        title
        user {
          legalName {
            firstName
          }
          avatarURL
        }
        images {
          thumbnailURL
        }
      }
      seekerUser {
        _id
        createDate
        avatarURL
        legalName {
          firstName
          lastName
          verified
        }
      }
      bookingId
      booking {
        dateStart
        dateEnd
      }
    }
    ... on GenericError {
      errorCode
      errorMessage
    }
  }
}
    `;
export const useChatChannelQuery = <TData = ChatChannelQuery, TError = unknown>(
  variables: ChatChannelQueryVariables,
  options?: UseQueryOptions<ChatChannelQuery, TError, TData>
) =>
  useQuery<ChatChannelQuery, TError, TData>(
    ["ChatChannel", variables],
    fetcher<ChatChannelQuery, ChatChannelQueryVariables>(ChatChannelDocument, variables),
    options
  );

useChatChannelQuery.getKey = (variables: ChatChannelQueryVariables) => ["ChatChannel", variables];
useChatChannelQuery.fetcher = (variables: ChatChannelQueryVariables) =>
  fetcher<ChatChannelQuery, ChatChannelQueryVariables>(ChatChannelDocument, variables);
export const ContactPageDocument = `
    query ContactPage {
  ContactPage {
    heading
    body
    cacheTime
  }
}
    `;
export const useContactPageQuery = <TData = ContactPageQuery, TError = unknown>(
  variables?: ContactPageQueryVariables,
  options?: UseQueryOptions<ContactPageQuery, TError, TData>
) =>
  useQuery<ContactPageQuery, TError, TData>(
    variables === undefined ? ["ContactPage"] : ["ContactPage", variables],
    fetcher<ContactPageQuery, ContactPageQueryVariables>(ContactPageDocument, variables),
    options
  );

useContactPageQuery.getKey = (variables?: ContactPageQueryVariables) =>
  variables === undefined ? ["ContactPage"] : ["ContactPage", variables];
useContactPageQuery.fetcher = (variables?: ContactPageQueryVariables) =>
  fetcher<ContactPageQuery, ContactPageQueryVariables>(ContactPageDocument, variables);
export const ContactUsDocument = `
    mutation ContactUs($email: String!, $subject: String!, $body: String!) {
  CreateSupportRequest(email: $email, subject: $subject, body: $body) {
    success
  }
}
    `;
export const useContactUsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<ContactUsMutation, TError, ContactUsMutationVariables, TContext>
) =>
  useMutation<ContactUsMutation, TError, ContactUsMutationVariables, TContext>(
    "ContactUs",
    (variables?: ContactUsMutationVariables) =>
      fetcher<ContactUsMutation, ContactUsMutationVariables>(ContactUsDocument, variables)(),
    options
  );
useContactUsMutation.fetcher = (variables: ContactUsMutationVariables) =>
  fetcher<ContactUsMutation, ContactUsMutationVariables>(ContactUsDocument, variables);
export const CreateListingDocument = `
    mutation CreateListing($title: String!, $description: String!, $keywords: [String!]!, $pricing: PricingInput!, $bondPrice: Float!, $condition: Condition!, $rules: [String!]!, $images: [ImageInput!]!, $categoryId: String!, $brand: String, $attributes: [AttributeValueInput!]!, $location: ListingLocationInput) {
  CreateListing(
    title: $title
    description: $description
    keywords: $keywords
    pricing: $pricing
    bondPrice: $bondPrice
    condition: $condition
    rules: $rules
    images: $images
    categoryId: $categoryId
    brand: $brand
    attributes: $attributes
    location: $location
  ) {
    __typename
    ... on CreateListingSuccess {
      listing {
        listingId
      }
    }
    ... on GenericError {
      errorCode
      errorMessage
    }
  }
}
    `;
export const useCreateListingMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateListingMutation,
    TError,
    CreateListingMutationVariables,
    TContext
  >
) =>
  useMutation<CreateListingMutation, TError, CreateListingMutationVariables, TContext>(
    "CreateListing",
    (variables?: CreateListingMutationVariables) =>
      fetcher<CreateListingMutation, CreateListingMutationVariables>(
        CreateListingDocument,
        variables
      )(),
    options
  );
useCreateListingMutation.fetcher = (variables: CreateListingMutationVariables) =>
  fetcher<CreateListingMutation, CreateListingMutationVariables>(CreateListingDocument, variables);
export const GetStripeTransfersStatusDocument = `
    query GetStripeTransfersStatus {
  GetStripeTransfersStatus
}
    `;
export const useGetStripeTransfersStatusQuery = <
  TData = GetStripeTransfersStatusQuery,
  TError = unknown
>(
  variables?: GetStripeTransfersStatusQueryVariables,
  options?: UseQueryOptions<GetStripeTransfersStatusQuery, TError, TData>
) =>
  useQuery<GetStripeTransfersStatusQuery, TError, TData>(
    variables === undefined
      ? ["GetStripeTransfersStatus"]
      : ["GetStripeTransfersStatus", variables],
    fetcher<GetStripeTransfersStatusQuery, GetStripeTransfersStatusQueryVariables>(
      GetStripeTransfersStatusDocument,
      variables
    ),
    options
  );

useGetStripeTransfersStatusQuery.getKey = (variables?: GetStripeTransfersStatusQueryVariables) =>
  variables === undefined ? ["GetStripeTransfersStatus"] : ["GetStripeTransfersStatus", variables];
useGetStripeTransfersStatusQuery.fetcher = (variables?: GetStripeTransfersStatusQueryVariables) =>
  fetcher<GetStripeTransfersStatusQuery, GetStripeTransfersStatusQueryVariables>(
    GetStripeTransfersStatusDocument,
    variables
  );
export const CheckOnboardingStatusDocument = `
    query CheckOnboardingStatus {
  OnboardingStatus: CheckOnboardingStatus {
    status
    profile {
      state
      city
      postalCode
      addressLine1
      dateOfBirthYear
      dateOfBirthMonth
      dateOfBirthDay
      lastName
      firstName
    }
    errors {
      code
      requirement
      reason
    }
  }
}
    `;
export const useCheckOnboardingStatusQuery = <TData = CheckOnboardingStatusQuery, TError = unknown>(
  variables?: CheckOnboardingStatusQueryVariables,
  options?: UseQueryOptions<CheckOnboardingStatusQuery, TError, TData>
) =>
  useQuery<CheckOnboardingStatusQuery, TError, TData>(
    variables === undefined ? ["CheckOnboardingStatus"] : ["CheckOnboardingStatus", variables],
    fetcher<CheckOnboardingStatusQuery, CheckOnboardingStatusQueryVariables>(
      CheckOnboardingStatusDocument,
      variables
    ),
    options
  );

useCheckOnboardingStatusQuery.getKey = (variables?: CheckOnboardingStatusQueryVariables) =>
  variables === undefined ? ["CheckOnboardingStatus"] : ["CheckOnboardingStatus", variables];
useCheckOnboardingStatusQuery.fetcher = (variables?: CheckOnboardingStatusQueryVariables) =>
  fetcher<CheckOnboardingStatusQuery, CheckOnboardingStatusQueryVariables>(
    CheckOnboardingStatusDocument,
    variables
  );
export const CreateDisputeDocument = `
    mutation CreateDispute($email: String!, $subject: String!, $body: String!, $bookingId: String!) {
  CreateSupportRequest(
    email: $email
    subject: $subject
    body: $body
    bookingId: $bookingId
  ) {
    success
  }
}
    `;
export const useCreateDisputeMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateDisputeMutation,
    TError,
    CreateDisputeMutationVariables,
    TContext
  >
) =>
  useMutation<CreateDisputeMutation, TError, CreateDisputeMutationVariables, TContext>(
    "CreateDispute",
    (variables?: CreateDisputeMutationVariables) =>
      fetcher<CreateDisputeMutation, CreateDisputeMutationVariables>(
        CreateDisputeDocument,
        variables
      )(),
    options
  );
useCreateDisputeMutation.fetcher = (variables: CreateDisputeMutationVariables) =>
  fetcher<CreateDisputeMutation, CreateDisputeMutationVariables>(CreateDisputeDocument, variables);
export const GetListingPageDocument = `
    query GetListingPage($id: String!) {
  Listing(listingId: $id) {
    __typename
    ... on InternalListing {
      listingId
      title
      description
      approved
      keywords
      category {
        categoryId
        categoryName
        urlSlug
        breadcrumb {
          categoryId
          categoryName
          urlSlug
        }
      }
      bondPrice
      pricing {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      images {
        _id
        imageURL
        thumbnailURL
        heroURL
        sortOrder
      }
      user {
        _id
        accountId
        createDate
        avatarURL
        legalName {
          firstName
          lastName
          verified
        }
      }
      location {
        national
        postcode
        locality
        state
        latitude
        longitude
      }
      brand
      condition
      rules
      attributes {
        attributeId
        attributeType
        attributeName
        valueInt
        valueFloat
        valueString
        valueStringArray
        valueBoolean
      }
    }
  }
}
    `;
export const useGetListingPageQuery = <TData = GetListingPageQuery, TError = unknown>(
  variables: GetListingPageQueryVariables,
  options?: UseQueryOptions<GetListingPageQuery, TError, TData>
) =>
  useQuery<GetListingPageQuery, TError, TData>(
    ["GetListingPage", variables],
    fetcher<GetListingPageQuery, GetListingPageQueryVariables>(GetListingPageDocument, variables),
    options
  );

useGetListingPageQuery.getKey = (variables: GetListingPageQueryVariables) => [
  "GetListingPage",
  variables,
];
useGetListingPageQuery.fetcher = (variables: GetListingPageQueryVariables) =>
  fetcher<GetListingPageQuery, GetListingPageQueryVariables>(GetListingPageDocument, variables);
export const UpdateListingDocument = `
    mutation UpdateListing($listingId: String!, $title: String!, $brand: String!, $description: String!, $keywords: [String!]!, $pricing: PricingInput!, $bondPrice: Float!, $condition: Condition!, $rules: [String!]!, $editImages: [EditListingImageInput!]!, $newImages: [ImageInput!]!, $categoryId: String!, $location: ListingLocationInput, $attributes: [AttributeValueInput!]!) {
  UpdateListing(
    listingId: $listingId
    title: $title
    brand: $brand
    description: $description
    keywords: $keywords
    pricing: $pricing
    bondPrice: $bondPrice
    condition: $condition
    rules: $rules
    newImages: $newImages
    categoryId: $categoryId
    location: $location
    editImages: $editImages
    attributes: $attributes
  ) {
    __typename
    ... on EditListingSuccess {
      listing {
        listingId
      }
    }
    ... on GenericError {
      errorCode
      errorMessage
    }
  }
}
    `;
export const useUpdateListingMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateListingMutation,
    TError,
    UpdateListingMutationVariables,
    TContext
  >
) =>
  useMutation<UpdateListingMutation, TError, UpdateListingMutationVariables, TContext>(
    "UpdateListing",
    (variables?: UpdateListingMutationVariables) =>
      fetcher<UpdateListingMutation, UpdateListingMutationVariables>(
        UpdateListingDocument,
        variables
      )(),
    options
  );
useUpdateListingMutation.fetcher = (variables: UpdateListingMutationVariables) =>
  fetcher<UpdateListingMutation, UpdateListingMutationVariables>(UpdateListingDocument, variables);
export const GetOwnerBookingHistoryDocument = `
    query GetOwnerBookingHistory {
  OwnerBookingHistory {
    __typename
    ... on OwnerBookingHistory {
      current {
        ...RentalItem_Booking
      }
      upcoming {
        ...RentalItem_Booking
      }
      past {
        ...RentalItem_Booking
      }
    }
    ... on GenericError {
      errorCode
      errorMessage
    }
  }
}
    ${RentalItem_BookingFragmentDoc}`;
export const useGetOwnerBookingHistoryQuery = <
  TData = GetOwnerBookingHistoryQuery,
  TError = unknown
>(
  variables?: GetOwnerBookingHistoryQueryVariables,
  options?: UseQueryOptions<GetOwnerBookingHistoryQuery, TError, TData>
) =>
  useQuery<GetOwnerBookingHistoryQuery, TError, TData>(
    variables === undefined ? ["GetOwnerBookingHistory"] : ["GetOwnerBookingHistory", variables],
    fetcher<GetOwnerBookingHistoryQuery, GetOwnerBookingHistoryQueryVariables>(
      GetOwnerBookingHistoryDocument,
      variables
    ),
    options
  );

useGetOwnerBookingHistoryQuery.getKey = (variables?: GetOwnerBookingHistoryQueryVariables) =>
  variables === undefined ? ["GetOwnerBookingHistory"] : ["GetOwnerBookingHistory", variables];
useGetOwnerBookingHistoryQuery.fetcher = (variables?: GetOwnerBookingHistoryQueryVariables) =>
  fetcher<GetOwnerBookingHistoryQuery, GetOwnerBookingHistoryQueryVariables>(
    GetOwnerBookingHistoryDocument,
    variables
  );
export const OwnerRejectBookingDocument = `
    mutation OwnerRejectBooking($id: String!) {
  OwnerRejectBooking(bookingId: $id) {
    __typename
    ... on GenericError {
      errorCode
      errorMessage
    }
  }
}
    `;
export const useOwnerRejectBookingMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    OwnerRejectBookingMutation,
    TError,
    OwnerRejectBookingMutationVariables,
    TContext
  >
) =>
  useMutation<OwnerRejectBookingMutation, TError, OwnerRejectBookingMutationVariables, TContext>(
    "OwnerRejectBooking",
    (variables?: OwnerRejectBookingMutationVariables) =>
      fetcher<OwnerRejectBookingMutation, OwnerRejectBookingMutationVariables>(
        OwnerRejectBookingDocument,
        variables
      )(),
    options
  );
useOwnerRejectBookingMutation.fetcher = (variables: OwnerRejectBookingMutationVariables) =>
  fetcher<OwnerRejectBookingMutation, OwnerRejectBookingMutationVariables>(
    OwnerRejectBookingDocument,
    variables
  );
export const OwnerApproveBookingDocument = `
    mutation OwnerApproveBooking($id: String!) {
  OwnerApproveBooking(bookingId: $id) {
    __typename
    ... on GenericError {
      errorCode
      errorMessage
    }
  }
}
    `;
export const useOwnerApproveBookingMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    OwnerApproveBookingMutation,
    TError,
    OwnerApproveBookingMutationVariables,
    TContext
  >
) =>
  useMutation<OwnerApproveBookingMutation, TError, OwnerApproveBookingMutationVariables, TContext>(
    "OwnerApproveBooking",
    (variables?: OwnerApproveBookingMutationVariables) =>
      fetcher<OwnerApproveBookingMutation, OwnerApproveBookingMutationVariables>(
        OwnerApproveBookingDocument,
        variables
      )(),
    options
  );
useOwnerApproveBookingMutation.fetcher = (variables: OwnerApproveBookingMutationVariables) =>
  fetcher<OwnerApproveBookingMutation, OwnerApproveBookingMutationVariables>(
    OwnerApproveBookingDocument,
    variables
  );
export const OwnerReleaseBookingDocument = `
    mutation OwnerReleaseBooking($id: String!) {
  OwnerReleaseBooking(bookingId: $id) {
    __typename
    ... on GenericError {
      errorCode
      errorMessage
    }
  }
}
    `;
export const useOwnerReleaseBookingMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    OwnerReleaseBookingMutation,
    TError,
    OwnerReleaseBookingMutationVariables,
    TContext
  >
) =>
  useMutation<OwnerReleaseBookingMutation, TError, OwnerReleaseBookingMutationVariables, TContext>(
    "OwnerReleaseBooking",
    (variables?: OwnerReleaseBookingMutationVariables) =>
      fetcher<OwnerReleaseBookingMutation, OwnerReleaseBookingMutationVariables>(
        OwnerReleaseBookingDocument,
        variables
      )(),
    options
  );
useOwnerReleaseBookingMutation.fetcher = (variables: OwnerReleaseBookingMutationVariables) =>
  fetcher<OwnerReleaseBookingMutation, OwnerReleaseBookingMutationVariables>(
    OwnerReleaseBookingDocument,
    variables
  );
export const OwnerReturnBookingDocument = `
    mutation OwnerReturnBooking($id: String!) {
  OwnerReturnBooking(bookingId: $id) {
    __typename
    ... on GenericError {
      errorCode
      errorMessage
    }
  }
}
    `;
export const useOwnerReturnBookingMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    OwnerReturnBookingMutation,
    TError,
    OwnerReturnBookingMutationVariables,
    TContext
  >
) =>
  useMutation<OwnerReturnBookingMutation, TError, OwnerReturnBookingMutationVariables, TContext>(
    "OwnerReturnBooking",
    (variables?: OwnerReturnBookingMutationVariables) =>
      fetcher<OwnerReturnBookingMutation, OwnerReturnBookingMutationVariables>(
        OwnerReturnBookingDocument,
        variables
      )(),
    options
  );
useOwnerReturnBookingMutation.fetcher = (variables: OwnerReturnBookingMutationVariables) =>
  fetcher<OwnerReturnBookingMutation, OwnerReturnBookingMutationVariables>(
    OwnerReturnBookingDocument,
    variables
  );
export const GetUserListingsDocument = `
    query GetUserListings {
  Listings(filter: {loggedInUser: true}) {
    ...ListingItem_Listing
  }
}
    ${ListingItem_ListingFragmentDoc}`;
export const useGetUserListingsQuery = <TData = GetUserListingsQuery, TError = unknown>(
  variables?: GetUserListingsQueryVariables,
  options?: UseQueryOptions<GetUserListingsQuery, TError, TData>
) =>
  useQuery<GetUserListingsQuery, TError, TData>(
    variables === undefined ? ["GetUserListings"] : ["GetUserListings", variables],
    fetcher<GetUserListingsQuery, GetUserListingsQueryVariables>(
      GetUserListingsDocument,
      variables
    ),
    options
  );

useGetUserListingsQuery.getKey = (variables?: GetUserListingsQueryVariables) =>
  variables === undefined ? ["GetUserListings"] : ["GetUserListings", variables];
useGetUserListingsQuery.fetcher = (variables?: GetUserListingsQueryVariables) =>
  fetcher<GetUserListingsQuery, GetUserListingsQueryVariables>(GetUserListingsDocument, variables);
export const PuaseListingDocument = `
    mutation PuaseListing($listingId: String!) {
  ToggleListing(listingId: $listingId)
}
    `;
export const usePuaseListingMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PuaseListingMutation,
    TError,
    PuaseListingMutationVariables,
    TContext
  >
) =>
  useMutation<PuaseListingMutation, TError, PuaseListingMutationVariables, TContext>(
    "PuaseListing",
    (variables?: PuaseListingMutationVariables) =>
      fetcher<PuaseListingMutation, PuaseListingMutationVariables>(
        PuaseListingDocument,
        variables
      )(),
    options
  );
usePuaseListingMutation.fetcher = (variables: PuaseListingMutationVariables) =>
  fetcher<PuaseListingMutation, PuaseListingMutationVariables>(PuaseListingDocument, variables);
export const AddExternalBankAccountDocument = `
    mutation AddExternalBankAccount($bankAccountToken: String!) {
  AddExternalBankAccount(bankAccountToken: $bankAccountToken) {
    success
  }
}
    `;
export const useAddExternalBankAccountMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AddExternalBankAccountMutation,
    TError,
    AddExternalBankAccountMutationVariables,
    TContext
  >
) =>
  useMutation<
    AddExternalBankAccountMutation,
    TError,
    AddExternalBankAccountMutationVariables,
    TContext
  >(
    "AddExternalBankAccount",
    (variables?: AddExternalBankAccountMutationVariables) =>
      fetcher<AddExternalBankAccountMutation, AddExternalBankAccountMutationVariables>(
        AddExternalBankAccountDocument,
        variables
      )(),
    options
  );
useAddExternalBankAccountMutation.fetcher = (variables: AddExternalBankAccountMutationVariables) =>
  fetcher<AddExternalBankAccountMutation, AddExternalBankAccountMutationVariables>(
    AddExternalBankAccountDocument,
    variables
  );
export const UpdateUserDocument = `
    mutation UpdateUser($firstName: String, $lastName: String, $aboutMe: String) {
  UpdateUser(firstName: $firstName, lastName: $lastName, aboutMe: $aboutMe) {
    ...AuthenticatedUserFragment
  }
}
    ${AuthenticatedUserFragmentDoc}`;
export const useUpdateUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>
) =>
  useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
    "UpdateUser",
    (variables?: UpdateUserMutationVariables) =>
      fetcher<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables)(),
    options
  );
useUpdateUserMutation.fetcher = (variables?: UpdateUserMutationVariables) =>
  fetcher<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables);
export const ChangeEmailAddressDocument = `
    mutation ChangeEmailAddress($email: String!) {
  ChangeEmailAddress(email: $email) {
    __typename
    ... on GenericSuccessType {
      success
    }
    ... on GenericError {
      errorCode
      errorMessage
    }
  }
}
    `;
export const useChangeEmailAddressMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ChangeEmailAddressMutation,
    TError,
    ChangeEmailAddressMutationVariables,
    TContext
  >
) =>
  useMutation<ChangeEmailAddressMutation, TError, ChangeEmailAddressMutationVariables, TContext>(
    "ChangeEmailAddress",
    (variables?: ChangeEmailAddressMutationVariables) =>
      fetcher<ChangeEmailAddressMutation, ChangeEmailAddressMutationVariables>(
        ChangeEmailAddressDocument,
        variables
      )(),
    options
  );
useChangeEmailAddressMutation.fetcher = (variables: ChangeEmailAddressMutationVariables) =>
  fetcher<ChangeEmailAddressMutation, ChangeEmailAddressMutationVariables>(
    ChangeEmailAddressDocument,
    variables
  );
export const GetRentalHistoryDocument = `
    query GetRentalHistory {
  RentalHistory {
    __typename
    ... on RentalHistory {
      current {
        ...RentalItem_Booking
      }
      upcoming {
        ...RentalItem_Booking
      }
      past {
        ...RentalItem_Booking
      }
    }
    ... on GenericError {
      errorCode
      errorMessage
    }
  }
}
    ${RentalItem_BookingFragmentDoc}`;
export const useGetRentalHistoryQuery = <TData = GetRentalHistoryQuery, TError = unknown>(
  variables?: GetRentalHistoryQueryVariables,
  options?: UseQueryOptions<GetRentalHistoryQuery, TError, TData>
) =>
  useQuery<GetRentalHistoryQuery, TError, TData>(
    variables === undefined ? ["GetRentalHistory"] : ["GetRentalHistory", variables],
    fetcher<GetRentalHistoryQuery, GetRentalHistoryQueryVariables>(
      GetRentalHistoryDocument,
      variables
    ),
    options
  );

useGetRentalHistoryQuery.getKey = (variables?: GetRentalHistoryQueryVariables) =>
  variables === undefined ? ["GetRentalHistory"] : ["GetRentalHistory", variables];
useGetRentalHistoryQuery.fetcher = (variables?: GetRentalHistoryQueryVariables) =>
  fetcher<GetRentalHistoryQuery, GetRentalHistoryQueryVariables>(
    GetRentalHistoryDocument,
    variables
  );
export const SeekerCancelBookingDocument = `
    mutation SeekerCancelBooking($id: String!) {
  SeekerCancelBooking(bookingId: $id) {
    __typename
    ... on GenericError {
      errorCode
      errorMessage
    }
  }
}
    `;
export const useSeekerCancelBookingMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SeekerCancelBookingMutation,
    TError,
    SeekerCancelBookingMutationVariables,
    TContext
  >
) =>
  useMutation<SeekerCancelBookingMutation, TError, SeekerCancelBookingMutationVariables, TContext>(
    "SeekerCancelBooking",
    (variables?: SeekerCancelBookingMutationVariables) =>
      fetcher<SeekerCancelBookingMutation, SeekerCancelBookingMutationVariables>(
        SeekerCancelBookingDocument,
        variables
      )(),
    options
  );
useSeekerCancelBookingMutation.fetcher = (variables: SeekerCancelBookingMutationVariables) =>
  fetcher<SeekerCancelBookingMutation, SeekerCancelBookingMutationVariables>(
    SeekerCancelBookingDocument,
    variables
  );
export const GetProfileDocument = `
    query GetProfile($userId: String!) {
  User(userId: $userId) {
    accountId
    createDate
    avatarURL
    aboutMe
    legalName {
      firstName
      verified
    }
  }
}
    `;
export const useGetProfileQuery = <TData = GetProfileQuery, TError = unknown>(
  variables: GetProfileQueryVariables,
  options?: UseQueryOptions<GetProfileQuery, TError, TData>
) =>
  useQuery<GetProfileQuery, TError, TData>(
    ["GetProfile", variables],
    fetcher<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, variables),
    options
  );

useGetProfileQuery.getKey = (variables: GetProfileQueryVariables) => ["GetProfile", variables];
useGetProfileQuery.fetcher = (variables: GetProfileQueryVariables) =>
  fetcher<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, variables);
export const ResetPasswordDocument = `
    mutation ResetPassword($newPassword: String!, $token: String!) {
  ResetPassword(newPassword: $newPassword, token: $token) {
    success
  }
}
    `;
export const useResetPasswordMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ResetPasswordMutation,
    TError,
    ResetPasswordMutationVariables,
    TContext
  >
) =>
  useMutation<ResetPasswordMutation, TError, ResetPasswordMutationVariables, TContext>(
    "ResetPassword",
    (variables?: ResetPasswordMutationVariables) =>
      fetcher<ResetPasswordMutation, ResetPasswordMutationVariables>(
        ResetPasswordDocument,
        variables
      )(),
    options
  );
useResetPasswordMutation.fetcher = (variables: ResetPasswordMutationVariables) =>
  fetcher<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, variables);
export const RequestPasswordResetDocument = `
    mutation RequestPasswordReset($email: String!) {
  RequestPasswordReset(email: $email) {
    success
  }
}
    `;
export const useRequestPasswordResetMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RequestPasswordResetMutation,
    TError,
    RequestPasswordResetMutationVariables,
    TContext
  >
) =>
  useMutation<
    RequestPasswordResetMutation,
    TError,
    RequestPasswordResetMutationVariables,
    TContext
  >(
    "RequestPasswordReset",
    (variables?: RequestPasswordResetMutationVariables) =>
      fetcher<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(
        RequestPasswordResetDocument,
        variables
      )(),
    options
  );
useRequestPasswordResetMutation.fetcher = (variables: RequestPasswordResetMutationVariables) =>
  fetcher<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(
    RequestPasswordResetDocument,
    variables
  );
export const VerifyEmailDocument = `
    mutation VerifyEmail($token: String!) {
  VerifyEmail(token: $token) {
    success
  }
}
    `;
export const useVerifyEmailMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<VerifyEmailMutation, TError, VerifyEmailMutationVariables, TContext>
) =>
  useMutation<VerifyEmailMutation, TError, VerifyEmailMutationVariables, TContext>(
    "VerifyEmail",
    (variables?: VerifyEmailMutationVariables) =>
      fetcher<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, variables)(),
    options
  );
useVerifyEmailMutation.fetcher = (variables: VerifyEmailMutationVariables) =>
  fetcher<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, variables);
