import React from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { IonIcon } from "@ionic/react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import tw from "twin.macro";

import { FormCallback } from "@app/typings";
import { SignUpSchema, SignUpType } from "@app/features/auth/validations";
import { Button, LabelledInput, Checkbox } from "@app/components/form";

import TermsAndConditions from "./TermsAndConditions";

interface ISignUpStepProps {
  onSubmit: FormCallback<SignUpType>;
}

const SignUpStep: React.FC<ISignUpStepProps> = ({ onSubmit }) => {
  const form = useForm<SignUpType>({
    defaultValues: { marketingComms: true, countryCode: 61 },
    resolver: zodResolver(SignUpSchema),
  });

  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <div tw="flex flex-col gap-4">
      <h4 tw="text-center text-lg font-medium">Confirm Your Details</h4>

      {/* Form */}
      <form
        onSubmit={form.handleSubmit((values) => onSubmit(values, form))}
        tw="grid grid-cols-2 gap-3"
      >
        <LabelledInput
          label="First Name"
          {...form.register("firstName")}
          isError={!!form.formState.errors.firstName}
          errorText={form.formState.errors.firstName?.message}
        />

        <LabelledInput
          label="Last Name"
          {...form.register("lastName")}
          isError={!!form.formState.errors.lastName}
          errorText={form.formState.errors.lastName?.message}
        />

        <LabelledInput
          label="Mobile Number"
          {...form.register("mobileNumber")}
          assistiveText="We will verify this number, have your phone with you."
          isError={!!form.formState.errors.mobileNumber}
          errorText={form.formState.errors.mobileNumber?.message}
          wrapperProps={{
            style: tw`col-span-2`,
          }}
        />

        <LabelledInput
          label="Password"
          type={showPassword ? "text" : "password"}
          {...form.register("password")}
          isError={!!form.formState.errors.password}
          errorText={form.formState.errors.password?.message}
          icon={
            <IonIcon
              icon={showPassword ? eyeOffOutline : eyeOutline}
              onClick={() => setShowPassword(!showPassword)}
              tw="cursor-pointer"
            />
          }
          wrapperProps={{
            style: tw`col-span-2`,
          }}
        />

        {/* <div tw="col-span-2 flex items-center gap-2">
          <Controller
            control={form.control}
            name="marketingComms"
            render={({ field: { value, onChange, ...field } }) => (
              <Checkbox
                checked={value}
                onIonChange={({ detail: { checked } }) => onChange(checked)}
                {...field}
              />
            )}
          />

          <label htmlFor="marketingComms" tw="text-sm">
            Be the first to hear about promotions and new items to rent
          </label>
        </div> */}

        <input type="submit" hidden />

        <Button
          type="submit"
          disabled={form.formState.isSubmitting}
          expand="block"
          tw="col-span-2 mt-4"
        >
          Continue
        </Button>
      </form>

      <TermsAndConditions tw="mt-2" />
    </div>
  );
};

export default SignUpStep;
