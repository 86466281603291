import React from "react";
import { IonIcon, IonModal } from "@ionic/react";
import DatePicker from "react-datepicker";
import format from "date-fns/format";
import tw, { styled, screen } from "twin.macro";

import Button from "../button";
import { Wrapper as InputWrapper } from "../input";
import { DatepickerColumn } from "./DatepickerColumn";

import { arrowForwardOutline, chevronBack, chevronForward } from "ionicons/icons";

type ReactDatePickerProps = React.ComponentPropsWithoutRef<typeof DatePicker>;

interface IDatepickerProps extends ReactDatePickerProps {
  onReset: () => void;
}

const Datepicker: React.FC<IDatepickerProps> = ({ onReset, ...props }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <DatePickerInput start={props.startDate} end={props.endDate} onClick={() => setOpen(true)} />
      <IonModal
        isOpen={open}
        onIonModalDidDismiss={() => setOpen(false)}
        css={{
          "--background": "unset",
          "--width": "unset",
          "--height": "unset",
        }}
      >
        {/*  @ts-ignore Weird type error due to `screen` import from twin.macro... Man, these types */}
        <Wrapper>
          <DatePicker
            monthsShown={2}
            selectsRange
            dateFormat="dd/MM/yyyy"
            disabledKeyboardNavigation
            shouldCloseOnSelect={false}
            showPopperArrow={false}
            fixedHeight
            calendarContainer={DatePickerContainer({ onApply: () => setOpen(false), onReset })}
            renderCustomHeader={DatePickerHeader}
            inline
            {...props}
          />
        </Wrapper>
      </IonModal>
    </>
  );
};

export default Datepicker;

type DatePickerContainerType = (options: {
  onApply: () => void;
  onReset: () => void;
}) => ReactDatePickerProps["calendarContainer"];

const DatePickerContainer: DatePickerContainerType =
  ({ onApply, onReset }) =>
  ({ children }) =>
    (
      <div
        className="react-datepicker-wrapper"
        tw="bg-white p-8 rounded-xl border border-solid border-gray-100 shadow-lg flex flex-col"
      >
        <div tw="flex flex-col md:flex-row gap-x-8">{children}</div>

        <div tw="flex items-center justify-end gap-x-2 mt-4">
          <Button fill="clear" color="success" size="small" onClick={() => onReset()}>
            Reset dates
          </Button>

          <Button size="small" onClick={() => onApply()}>
            Apply
          </Button>
        </div>
      </div>
    );

const DatePickerHeader: ReactDatePickerProps["renderCustomHeader"] = ({
  monthDate,
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
}) => (
  <div tw="mb-4">
    <button
      aria-label="Previous Month"
      onClick={decreaseMonth}
      className={"react-datepicker__navigation react-datepicker__navigation--prev"}
      tw="outline-none! rounded hover:bg-off-white focus:bg-gray-100 "
      css={[customHeaderCount === 1 && tw`hidden`]}
    >
      <IonIcon icon={chevronBack} tw="w-4 h-4" />
    </button>

    <span className="react-datepicker__current-month" tw="text-base font-normal">
      {monthDate.toLocaleString("en-US", {
        month: "long",
        year: "numeric",
      })}
    </span>

    <button
      aria-label="Next Month"
      onClick={increaseMonth}
      className={"react-datepicker__navigation react-datepicker__navigation--next"}
      tw="outline-none! rounded hover:bg-off-white focus:bg-gray-100 "
      css={[customHeaderCount === 0 && tw`hidden`]}
    >
      <IonIcon icon={chevronForward} tw="w-4 h-4" />
    </button>
  </div>
);

const DatePickerInput: React.FC<{ start?: Date | null; end?: Date | null; onClick: () => void }> =
  ({ start, end, onClick }) => {
    const startDate = start ? format(start, "dd/MM/yyyy") : undefined;
    const endDate = end ? format(end, "dd/MM/yyyy") : undefined;

    return (
      <InputWrapper onClick={onClick} tw="h-14 cursor-pointer">
        <DatepickerColumn type="from" date={startDate} tw="flex-1" />
        <IonIcon icon={arrowForwardOutline} tw="flex-shrink-0" />
        <DatepickerColumn type="to" date={endDate} tw="flex-1" />
      </InputWrapper>
    );
  };

/** Styles */

const Wrapper = styled.div`
  .react-datepicker {
    ${tw`
      p-8
      rounded-xl border-gray-100
      shadow-lg
      font-sans
      w-full
    `}

    &-popper {
      z-index: 1000;
    }

    &__header {
      ${tw`bg-transparent border-0`}
    }

    &__day-name,
    &__day {
      ${tw`mx-0 text-sm w-11 leading-8`}
    }

    &__day-name {
      ${tw`text-gray-400`}
    }

    &__month {
      min-height: 230px;

      ${screen`lg`} {
        min-height: unset;
      }
    }

    &__day {
      ${tw`rounded-none`}

      &--outside-month {
        ${tw`invisible`}
      }

      &--selected,
      &--keyboard-selected {
        ${tw`rounded-full bg-primary text-black`}
      }

      &--in-range,
      &--in-selecting-range {
        ${tw`bg-pastel-green text-black`}
      }

      &--range-start,
      &--range-end,
      &--selecting-range-start,
      &--selecting-range-end {
        ${tw`bg-primary`}
      }

      &--range-start,
      &--selecting-range-start {
        ${tw`rounded-l-full rounded-r-none`}
      }

      &--range-end,
      &--selecting-range-end {
        ${tw`rounded-r-full rounded-l-none`}
      }

      &--disabled,
      &--excluded {
        &.react-datepicker__day--in-range {
          ${tw`bg-pastel-pink`}
        }
      }
    }
  }
`;
